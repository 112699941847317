import { createAsyncThunk } from "@reduxjs/toolkit";
import { headerServices } from "../../services/header.services";

import { dashboardServices, IUnit } from "../../services/dashboard.services";
import { IOrganizationData } from "./headerSlice";

export const getHeaderInformation = createAsyncThunk(
  "header/getHeaderInformation",
  async () => {
    const [communities, userDetails, userRoles] = await Promise.all([
      headerServices.getCommunities(),
      headerServices.getUserDetails(),
      headerServices.getRoleTypes(),
    ]);
    return { communities, userDetails, userRoles };
  }
);

export const loadCommunityData = createAsyncThunk(
  "header/loadCommunityData",
  async (community_id: string) => {
    const [users, eventResolutions, units] = await Promise.all([
      headerServices.getCommunityUsers(community_id),
      headerServices.getEventResolutions(community_id),
      dashboardServices.getUnits(community_id),
    ]);
    return { users, eventResolutions, units };
  }
);

export const loadOrganizationData = createAsyncThunk(
  "header/loadOrganizationData",
  async (communities: string[]) => {
    const results = await Promise.all(
      communities.map((communityID) => {
        return Promise.all([
          headerServices.getCommunityUsers(communityID),
          headerServices.getEventResolutions(communityID),
          dashboardServices.getUnits(communityID),
        ]);
      })
    );
    const organizationData: IOrganizationData = {};
    const orgUnits: { [key: string]: IUnit[] } = {};
    communities.forEach((communityID, index) => {
      if (results[index].length < 3) return;
      organizationData[communityID] = {
        users: results[index][0],
        eventResolutions: results[index][1],
      };
      orgUnits[communityID] = results[index][2];
    });

    return { organizationData, orgUnits };
  }
);

export const updateCommunityBadges = createAsyncThunk(
  "header/updateCommunityBadges",
  async () => {
    const communities = await headerServices.getCommunities();

    return communities;
  }
);

export const loadCommunityGroups = createAsyncThunk(
  "header/loadCommunityGroups",
  async (community_id: string) => {
    const communities = await headerServices.getCommunityGroups(community_id);
    return communities;
  }
);

export const loadCommunityUsers = createAsyncThunk(
  "header/loadCommunityUsers",
  async (communityID: string) => {
    const users = await headerServices.getCommunityUsers(communityID);
    return users;
  }
);

export const getCommunityUnits = createAsyncThunk(
  "header/getCommunityUnits",
  async (communityID: string) => {
    const response = dashboardServices.getUnits(communityID);
    return response;
  }
);
