import {
  ContactTypes,
  RoleTypes,
  sortAlphabetical,
} from "../../helpers/constants";
import {
  EmergencyContact,
  IResidentContact,
} from "../../services/common.services";
import { IUserDetails } from "../../services/header.services";
import { isPendingUser as isNotPendingUser } from "../dashboard/dashboardSlice";
import { getUserName } from "./UsersSettingsList";

export const isEmergencyContact = (residentContact: IResidentContact) =>
  residentContact.emergency_contact;

export const sortContacts = (a: IResidentContact, b: IResidentContact) => {
  const priorityByContactType: { [key: string]: number } = {
    [ContactTypes.CAREGIVER]: 0,
    [ContactTypes.USER]: 1,
    [ContactTypes.FAMILY]: 2,
    [ContactTypes.NEIGHBOR]: 3,
    [ContactTypes.FRIEND]: 4,
    [ContactTypes.DOCTOR]: 5,
    [ContactTypes.NURSE]: 6,
  };

  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  });

  const contactTypePriorityA = priorityByContactType[a.contact_type];
  const contactTypePriorityB = priorityByContactType[b.contact_type];

  const contactLastNameA = a.last_name ?? "";
  const contactLastNameB = b.last_name ?? "";

  const contactFirstNameA = a.first_name ?? "";
  const contactFirstNameB = b.first_name ?? "";

  if (contactTypePriorityA > contactTypePriorityB) {
    return 1;
  }
  if (contactTypePriorityA < contactTypePriorityB) {
    return -1;
  }

  if (collator.compare(contactLastNameA, contactLastNameB) !== 0) {
    return collator.compare(contactLastNameA, contactLastNameB);
  }

  return collator.compare(contactFirstNameA, contactFirstNameB);
};

export const sortEmergencyContacts = (
  a: EmergencyContact,
  b: EmergencyContact
) => {
  if (a.position > b.position) {
    return 1;
  }
  return -1;
};

export const hasPhoneNumbers = <T extends { phone_numbers?: any[] | null }>(
  user: T
) => (user.phone_numbers ? user.phone_numbers.length > 0 : false);

export const getEmergencyAppUsers = (
  users: IUserDetails[],
  selectedUnitID?: string
): IUserDetails[] => {
  const result = users
    .filter(
      (user) =>
        user.role.name === RoleTypes.pro_family ||
        user.role.name === RoleTypes.caregiver
    )
    //Filter units for the selected unit
    .filter(
      (user) =>
        user.units.find((unit) => unit.id === selectedUnitID) !== undefined
    )
    .sort((a, b) => {
      if (isNotPendingUser(a) && !isNotPendingUser(b)) return -1;
      if (!isNotPendingUser(b) && !isNotPendingUser(b)) return 1;
      if (isNotPendingUser(a) && isNotPendingUser(b)) return 0;
      if (!isNotPendingUser(a) && !isNotPendingUser(b)) return 0;
      return 0;
    })
    .sort((a, b) => {
      if (isNotPendingUser(a) && isNotPendingUser(b)) {
        return sortAlphabetical(getUserName(a), getUserName(b));
      }
      if (!isNotPendingUser(a) && !isNotPendingUser(b)) {
        return sortAlphabetical(a.email, b.email);
      }
      return 0;
    });
  return result;
};

export const getOrderedResidentContacts = (contacts?: IResidentContact[]) => {
  let residentContacts: IResidentContact[] = [];

  if (contacts && contacts.length > 0) {
    residentContacts = contacts
      .filter((contact) => hasPhoneNumbers(contact))
      .sort((a, b) => sortContacts(a, b));
  }

  return [...residentContacts];
};

export const getRoleLabel = (role: string | undefined) => {
  if (role === undefined) return "";
  return role.replace("pro:", "");
};
