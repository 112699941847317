import React, { useEffect, useRef, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { IUserDetails } from "../../../services/header.services";
import { FilterSearchField } from "../../notifications/filters/FilterSearchField";
import { GreenCheckbox } from "../../notifications/filters/GreenCheckBox";
import { getUserName } from "../UsersSettingsList";
import { sortUsers } from "./helpers";
import { useTranslation } from "react-i18next";
import { RoleTitles, RoleTypes } from "../../../helpers/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverContainer: {
      width: "100%",
      height: "400px",
      overflowY: "auto",
      overflowX: "hidden",
      backgroundColor: theme.palette.light.light,
    },
    listRoot: {
      paddingTop: 0,
      paddingBottom: 0,
      "&$selected:hover": {
        backgroundColor: "inherit",
        color: "inherit",
      },
      "&:hover": {
        backgroundColor: "inherit",
        color: "inherit",
      },
    },
    listItemTextRoot: {
      margin: theme.spacing(1, 0),
    },
    listLabel: {
      fontWeight: "bold",
      paddingRight: theme.spacing(0.5),
    },
    truncateEllipsis: {
      whiteSpace: "nowrap",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-o-text-overflow": "ellipsis",
    },
    listIcon: {
      minWidth: "25px",
    },
    popoverHeader: {
      display: "flex",
    },
    selected: {},
    divider: {
      margin: theme.spacing(0.5, 0, 2, 0),
    },
    roleTitle: {
      paddingLeft: "10px",
      paddingBottom: "5px",
    },
    titlePadding: {
      paddingTop: "25px",
    },
  })
);

export const UserSelector = (props: IProps) => {
  const { selectedGroup, selectedUsers, users, handleUsersChange } = props;

  /* ---- Hooks ---- */
  const classes = useStyles();
  const { t } = useTranslation();

  /* ---- Refs ---- */
  const listContainer = useRef<HTMLUListElement | null>(null);

  /* ---- State ---- */
  const [filterSelection, setFilterSelection] = useState<{
    [key: string]: boolean;
  }>({});
  const [filteredOptions, setFilteredOptions] = useState<IUserDetails[]>([]);

  /* ---- Effects ---- */
  useEffect(() => {
    setFilteredOptions(sortUsers(users ?? []));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (users === undefined) return;
    let selectedOptions: { [key: string]: boolean } = {};
    users.map((user) =>
      selectedUsers.includes(user.id)
        ? (selectedOptions[user.id] = true)
        : (selectedOptions[user.id] = false)
    );
    setFilterSelection(selectedOptions);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers]);

  useEffect(() => {
    if (listContainer && listContainer.current) {
      listContainer.current.scrollTo(0, 0);
    }
  }, [selectedGroup]);

  const filteredOptionKeys = filteredOptions.map((option) => option.id);

  // Check if all searched for values are selected
  const allSelected = Object.keys(filterSelection)
    .filter((_key) => filteredOptionKeys.includes(_key))
    .every((val) => filterSelection[val]);

  // Check if all searched for values are not selected
  const noneSelected = Object.keys(filterSelection)
    .filter((_key) => filteredOptionKeys.includes(_key))
    .every((val) => !filterSelection[val]);

  /* ---- Methods ---- */
  const handleChange = (eventType: string) => {
    setFilterSelection((prevState) => {
      const newState = {
        ...prevState,
        [eventType]: !prevState[eventType],
      };

      const updatedString = JSON.parse(
        JSON.stringify(
          Object.keys(newState)
            .filter((key) => newState[key])
            .map((key) => key)
        )
      );

      handleUsersChange(updatedString);
      return newState;
    });
  };

  const handleSelectAll = () => {
    setFilterSelection((prevState) => {
      const keysToUpdate = Object.keys(prevState).filter((option) =>
        filteredOptionKeys.includes(option)
      );
      const newState: {
        [key: string]: boolean;
      } = {};
      if (allSelected) {
        keysToUpdate.forEach((option) => (newState[option as string] = false));
      } else {
        keysToUpdate.forEach((option) => (newState[option as string] = true));
      }

      const updatedString = JSON.parse(
        JSON.stringify(
          Object.keys({ ...prevState, ...newState })
            .filter((key) => newState[key])
            .map((key) => key)
        )
      );

      handleUsersChange(updatedString);
      return { ...prevState, ...newState };
    });
  };

  const handleSearchField = (searchValue: string) => {
    const filteredArray = users?.filter(
      (option) =>
        option.first_name?.toLowerCase().includes(searchValue) ||
        option.last_name?.toLowerCase().includes(searchValue) ||
        option.email?.toLowerCase().includes(searchValue)
    );
    setFilteredOptions(sortUsers(filteredArray ?? []));
  };

  const getRoleTitle = (roleName: string) => {
    switch (roleName) {
      case RoleTypes.admin:
        return RoleTitles.admin;
      case RoleTypes.caregiver:
        return RoleTitles.caregiver;
      case RoleTypes.installer:
        return RoleTitles.installer;
      default:
        return "";
    }
  };

  type GroupedUsers = {
    [roleName: string]: IUserDetails[];
  };

  const groupByRole = (users: IUserDetails[]): GroupedUsers => {
    return users.reduce((acc: GroupedUsers, user: IUserDetails) => {
      const roleName = user.role.name;
      if (!acc[roleName]) {
        acc[roleName] = [];
      }
      acc[roleName].push(user);
      return acc;
    }, {});
  };

  const roleOrder = [RoleTypes.admin, RoleTypes.caregiver, RoleTypes.installer];
  const groupedUsers = groupByRole(filteredOptions);

  return (
    <React.Fragment>
      <div className={classes.popoverHeader}>
        <FilterSearchField
          withApplyButton={false}
          placeholder={"search_responder"}
          allSelected={allSelected}
          noneSelected={noneSelected}
          selectAllCheckbox
          handleSelectAll={handleSelectAll}
          handleSearchField={handleSearchField}
        />
      </div>

      <Divider className={classes.divider} />
      <List
        ref={listContainer}
        aria-label="user list"
        className={classes.popoverContainer}
      >
        {roleOrder.map((roleName) => (
          <React.Fragment key={roleName}>
            {groupedUsers[roleName] && (
              <>
                <Typography className={classes.roleTitle}>
                  {t(getRoleTitle(roleName))}
                </Typography>
                {groupedUsers[roleName].map((option) => {
                  const userName = getUserName(option);
                  return (
                    <ListItem
                      classes={{ root: classes.listRoot }}
                      key={option.id}
                      id={option.id}
                      button
                      component="li"
                      onClick={() => handleChange(option.id)}
                    >
                      <ListItemIcon
                        classes={{
                          root: classes.listIcon,
                        }}
                      >
                        <GreenCheckbox
                          edge="start"
                          checked={filterSelection[option.id]}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>

                      <ListItemText
                        classes={{ root: classes.listItemTextRoot }}
                        primary={
                          <Grid container>
                            <Grid item xs={5}>
                              <div
                                className={clsx(
                                  classes.listLabel,
                                  classes.truncateEllipsis
                                )}
                              >{`${userName !== "" ? userName : "-"}`}</div>
                            </Grid>

                            <Grid item xs={7}>
                              <div
                                className={clsx(
                                  classes.listLabel,
                                  classes.truncateEllipsis
                                )}
                              >{`${
                                option.email !== "" ? option.email : "-"
                              }`}</div>
                            </Grid>
                          </Grid>
                        }
                        disableTypography
                      />
                    </ListItem>
                  );
                })}
                <div className={classes.titlePadding}></div>
              </>
            )}
          </React.Fragment>
        ))}
      </List>
    </React.Fragment>
  );
};

interface IProps {
  selectedGroup?: string;
  selectedUsers: string[];
  users: IUserDetails[] | undefined;
  handleUsersChange: (payload: string[]) => void;
}
