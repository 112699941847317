import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { sortAlphabetical } from "../../../helpers/constants";
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { GreenCheckbox } from "./GreenCheckBox";
import { useAppSelector } from "../../app/appHooks";
import { isPendingUser } from "../../dashboard/dashboardSlice";
import { FilterSearchField } from "./FilterSearchField";
import { ApplyFilterButton } from "./ApplyFilterButton";
import clsx from "clsx";
import { applyFilters, clearFilter } from "../notificationsSlice";
import { useDispatch } from "react-redux";
import { IUserDetails } from "../../../services/header.services";
import { StackSwitch } from "../../settings/common/StackSwitch";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverContainer: {
      maxWidth: "300px",
      minWidth: "460px",
      maxHeight: "400px",
      overflowY: "auto",
      overflowX: "hidden",
      backgroundColor: theme.palette.light.light,
    },
    listRoot: {
      paddingTop: 0,
      paddingBottom: 0,
      "&$selected:hover": {
        backgroundColor: "inherit",
        color: "inherit",
      },
      "&:hover": {
        backgroundColor: "inherit",
        color: "inherit",
      },
    },
    listLabel: {
      fontWeight: "bold",
      paddingRight: theme.spacing(0.5),
    },
    truncateEllipsis: {
      whiteSpace: "nowrap",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-o-text-overflow": "ellipsis",
    },
    listIcon: {
      minWidth: "25px",
    },
    popoverHeader: {
      display: "flex",
    },
    selected: {},
    unassignedSwitchContainer: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      gap: "16px",
      padding: theme.spacing(1.5, 1),
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  })
);

export const ResponderFilter = (props: IProps) => {
  const { handleCloseFilter } = props;
  /* ---- Hooks ---- */
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedOptions = useAppSelector(
    (state) => state.notificationsState.filters["responder"]
  );
  const selectedStatus = useAppSelector(
    (state) => state.notificationsState.filters["status"]
  );

  const users = useAppSelector((state) =>
    state.headerState.users
      ?.filter((user) => isPendingUser(user))
      .sort((a, b) => sortAlphabetical(a.last_name, b.last_name))
  );

  // Create controlled list options
  let defaultState: { [key: string]: boolean } = {};
  if (selectedOptions === undefined) {
    users?.forEach((user) => (defaultState[user.id] = true));
  } else {
    defaultState = JSON.parse(JSON.stringify(selectedOptions));
  }

  // Create controlled list options
  let defaultUnassignedStatus = selectedStatus !== undefined;

  /* ---- State ---- */
  const [filterSelection, setFilterSelection] = useState(defaultState);
  const [filteredOptions, setFilteredOptions] = useState<IUserDetails[]>([]);
  const [showUnassigned, setShowUnassigned] = useState(defaultUnassignedStatus);

  /* ---- Effects ---- */
  useEffect(() => {
    setFilteredOptions(users ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedOptions !== undefined) {
      setFilterSelection(selectedOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Obtain keys for searched for values
  const filteredOptionKeys = filteredOptions.map((option) => option.id);

  // Check if all searched for values are selected
  const allSelected = Object.keys(filterSelection)
    .filter((_key) => filteredOptionKeys.includes(_key))
    .every((val) => filterSelection[val]);

  // Check if all searched for values are not selected
  const noneSelected = Object.keys(filterSelection)
    .filter((_key) => filteredOptionKeys.includes(_key))
    .every((val) => !filterSelection[val]);

  /* ---- Methods ---- */
  const handleChange = (eventType: string) => {
    setFilterSelection({
      ...filterSelection,
      [eventType]: !filterSelection[eventType],
    });
  };

  const handleSelectAll = () => {
    setFilterSelection((prevState) => {
      const keysToUpdate = Object.keys(prevState).filter((option) =>
        filteredOptionKeys.includes(option)
      );
      const newState: {
        [key: string]: boolean;
      } = {};
      if (allSelected) {
        keysToUpdate.forEach((option) => (newState[option as string] = false));
      } else {
        keysToUpdate.forEach((option) => (newState[option as string] = true));
      }
      return { ...prevState, ...newState };
    });
  };

  const handleSearchField = (searchVal: string) => {
    const filteredArray = users?.filter(
      (option) =>
        option.first_name?.toLowerCase().includes(searchVal) ||
        option.last_name?.toLowerCase().includes(searchVal) ||
        option.email?.toLowerCase().includes(searchVal)
    );
    setFilteredOptions(filteredArray ?? []);
  };

  const toggleShowUnassigned = () => setShowUnassigned((prevVal) => !prevVal);

  const handleApplyButton = () => {
    if (showUnassigned) {
      dispatch(clearFilter("responder"));
      dispatch(applyFilters({ key: "status", values: { unassigned: true } }));
    } else {
      dispatch(clearFilter("status"));
      dispatch(applyFilters({ key: "responder", values: filterSelection }));
    }
    handleCloseFilter();
  };

  return (
    <React.Fragment>
      <div className={classes.popoverHeader}>
        <div className={classes.unassignedSwitchContainer}>
          <StackSwitch
            checked={showUnassigned}
            onChange={toggleShowUnassigned}
          />
          {t("show_unassigned_only")}
        </div>
        <ApplyFilterButton
          defaultOptions={defaultState}
          currentOptions={filterSelection}
          onApplyFilter={handleApplyButton}
          needsToChangeToSave={false}
        />
      </div>
      <Divider />

      <List aria-label="responder list" className={classes.popoverContainer}>
        <FilterSearchField
          withApplyButton
          placeholder={"search_responder"}
          allSelected={allSelected}
          noneSelected={noneSelected}
          selectAllCheckbox
          disabled={showUnassigned}
          handleSelectAll={handleSelectAll}
          handleSearchField={handleSearchField}
        />
        {filteredOptions.map((option) => {
          return (
            <ListItem
              classes={{ root: classes.listRoot }}
              key={option.id}
              id={option.id}
              button
              disabled={showUnassigned}
              onClick={() => handleChange(option.id)}
            >
              <ListItemIcon
                classes={{
                  root: classes.listIcon,
                }}
              >
                <GreenCheckbox
                  edge="start"
                  checked={filterSelection[option.id]}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>

              <ListItemText
                primary={
                  <Grid container>
                    <Grid item xs={5}>
                      <div
                        className={clsx(
                          classes.listLabel,
                          classes.truncateEllipsis
                        )}
                      >{`${option.first_name} ${option.last_name}`}</div>
                    </Grid>

                    <Grid item xs={7}>
                      <div
                        className={clsx(
                          classes.listLabel,
                          classes.truncateEllipsis
                        )}
                      >{`${option.email !== "" ? option.email : "-"}`}</div>
                    </Grid>
                  </Grid>
                }
                disableTypography
              />
            </ListItem>
          );
        })}
      </List>
    </React.Fragment>
  );
};

interface IProps {
  handleCloseFilter: () => void;
}
