import * as QueryString from "query-string";
import { ALEXA, StorageKeys } from "../services/constants";
import { ReadStatus } from "./constants";
import { env } from "../services/env";

// Matches an email to a regex expression, returns true if expression is invalid
export const emailValidation = (emailToValidate: string): boolean => {
  return (
    /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}$/.test(
      emailToValidate
    ) === false
  );
};

// Validates a password field, returns a string code from i18n file
export const passwordValidation = (
  newPassword: string,
  confirmPassword?: string
): string => {
  if (newPassword !== "") {
    if (newPassword.length < 8) {
      return "password_requirements";
    } else if (/^(.*\d.*)$/.test(newPassword) === false) {
      return "password_requirements";
    } else if (/^(.*[A-Za-z].*)$/.test(newPassword) === false) {
      return "password_requirements";
    } else if (
      confirmPassword !== undefined &&
      newPassword !== "" &&
      confirmPassword !== "" &&
      newPassword !== confirmPassword
    ) {
      return "new_passwords_no_match";
    } else {
      return "";
    }
  } else {
    return "";
  }
};

// Validate if URL matchs the parameters from an Alexa request
export const validateAlexaAuth = (params: QueryString.ParsedQuery) => {
  const keys = ["client_id", "redirect_uri", "response_type", "scope", "state"];
  const allMatch = keys.every((key) => Object.keys(params).includes(key));
  return allMatch && params.scope === ALEXA;
};

// Returns an array of events that have stringified data and firebase key
export const getEventsToUpdateFirebase = (
  data: any,
  markUnread: boolean | undefined = undefined
): any[] => {
  const defaultData = JSON.stringify({ activity_data: [] });
  const vals = data;
  const _records: any = [];
  if (vals === null) return _records;
  for (var key in vals) {
    const objectKeys = Object.keys(vals[key]);
    let dataString;
    const dataExists = objectKeys.includes("data");
    if (dataExists && vals[key].data !== "") {
      const data = vals[key].data;
      dataString = JSON.stringify(data);
    }
    _records.push({
      ...vals[key],
      data: dataExists && vals[key].data !== "" ? dataString : defaultData,
      firebaseKey: key,
      read_status: markUnread ? ReadStatus.UNREAD : ReadStatus.READ,
    });
  }
  return _records;
};

export const currentEnv = () => {
  if (process.env.REACT_APP_ENVIRONMENT !== "production") {
    let environment = localStorage.getItem(StorageKeys.ENV);
    if (environment !== null) {
      return environment;
    }
  }
  if (env === undefined) {
    throw new Error("Environment not defined");
  }
  return `${env}`.replace("https://", "").replace(".appspot.com", "");
};

export function arraysAreEqual<T>(array1: T[], array2: T[]) {
  if (array1.length === array2.length) {
    return [...array1].sort().join(",") === [...array2].sort().join(",");
  }
  return false;
}

export const currentVer = () => {
  let version = localStorage.getItem(StorageKeys.VERSION);
  if (version !== null) {
    return version;
  }
  return "";
};
