import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Grid, { GridSize } from "@material-ui/core/Grid/Grid";
import EventWorkflow from "../eventWorkflow/EventWorkflow";
import EventComments from "../eventComments/EventComments";
import EventDetail from "../eventDetails/EventDetail";
import EventChart from "../charts/EventChart";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { IAppState } from "../../../helpers/store";
import clsx from "clsx";
import { Views } from "../../../helpers/constants";
import { isHardEvent } from "../dashboardSlice";

const useStyles = makeStyles((theme: Theme) => ({
  containerFullHeight: {
    height: "100%",
    "&>*": {
      height: "100%",
    },
    maxHeight: "100vh",
  },
  justifyDetails: {
    justifyContent: "space-between",
  },
  chartsContainer: {
    padding: theme.spacing(0, 3),
  },
  detailsContainer: {
    height: "100%",
    [theme.breakpoints.down("lg")]: {
      paddingRight: theme.spacing(1),
      position: "relative",
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
    },
  },
}));

export default function TrayEvent(props: IProps) {
  const { eventID } = props;
  /* ---- Hooks ---- */
  const classes = useStyles();
  const location = useLocation();

  /* ---- Selectors ---- */
  const dashboardEvent = useSelector((state: IAppState) => {
    return state.dashboardState.communityEvents?.find(
      (event) => event.id === eventID
    );
  });
  const notificationsEvent = useSelector((state: IAppState) => {
    return state.notificationsState.notifications?.find(
      (event) => event.id === eventID
    );
  });

  let event = undefined;
  if (location.pathname === Views.DASHBOARD) {
    event = dashboardEvent;
  } else if (location.pathname === Views.NOTIFICATIONS) {
    event = notificationsEvent;
  }

  const eventDataApiFailed = useSelector((state: IAppState) => {
    if (Object.keys(state.dashboardState.eventData).includes(eventID)) {
      return state.dashboardState.eventData[eventID].apiFailed;
    }
    return false;
  });

  const pathname = location.pathname;
  const layout: {
    [key: string]: { left: GridSize; center: GridSize; right: GridSize };
  } = {
    [Views.DASHBOARD]: {
      left: 3,
      center: 6,
      right: 3,
    },
    [Views.NOTIFICATIONS]: {
      left: 3,
      center: 6,
      right: 3,
    },
  };
  const isHard = event !== undefined && isHardEvent(event);

  return (
    <Grid container className={classes.containerFullHeight}>
      <Grid item xs={layout[pathname].left}>
        <div
          className={clsx(
            classes.detailsContainer,
            pathname === Views.NOTIFICATIONS && classes.justifyDetails
          )}
        >
          <EventDetail event={event} />
          {isHard && <EventWorkflow event={event} layout={pathname} />}
        </div>
      </Grid>
      <Grid
        item
        xs={layout[pathname].center}
        className={classes.chartsContainer}
      >
        <EventChart event={event} eventDataApiFailed={eventDataApiFailed} />
      </Grid>
      <Grid item xs={layout[pathname].right}>
        <EventComments event={event} />
      </Grid>
    </Grid>
  );
}

interface IProps {
  eventID: string;
}
