import React, { ReactNode } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import SectionHeader from "./SectionHeader";
import { useTranslation } from "react-i18next";
import { IconButton } from "@material-ui/core";
import Icons from "../../../helpers/iconImports";
import { Grid } from "@material-ui/core";
import {
  AnalyticsChartTypes,
  EventTypes,
  Views,
} from "../../../helpers/constants";
import { useAppDispatch } from "../../app/appHooks";
import { useHistory } from "react-router-dom";
import { setSelectedUnit } from "../../analytics/analyticsSlice";
import {
  expandAnalyticsChart,
  getUnitChartData,
} from "../../analytics/analyticsThunks";
import { DateTime } from "luxon";

const useStyles = makeStyles<Theme, Boolean>((theme: Theme) =>
  createStyles({
    container: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      "& > *": {
        minWidth: "300px",
      },
    },
    containerA: {
      height: "100%",
      width: "100%",
      paddingTop: (emptyData) => (emptyData ? "10px" : "0px"),
    },
    centerSelf: {
      alignSelf: "center",
    },
    sectionTitle: {
      alignItems: "center",
    },
    iconButton: {
      padding: "0",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    iconBox: {
      textAlign: "right",
    },
    icon: {
      width: "20px",
      height: "20px",
    },
  })
);

export const LayoutChartContainer = (props: {
  children: ReactNode;
  title: string;
  eventTimeCreated: string;
  eventType?: string;
  complementaryText?: string;
  endText?: string;
  unitID: string;
  emptyData: boolean;
}) => {
  /*TODO: Make containerA height dynamic by substracting siblings heights */
  const classes = useStyles(props.emptyData);

  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const getZoomByEventType = (eventType?: string) => {
    switch (eventType) {
      case EventTypes.activity_monitor_day:
      case EventTypes.activity_monitor_night:
      case EventTypes.activity_monitor_kitchen:
        return 24;
      case EventTypes.refrigerator_door_open:
      case EventTypes.exterior_door_open:
        return 1;
      default:
        return 6;
    }
  };

  const analyticsRedirect = async () => {
    if (props.unitID !== "") {
      const eventTime = DateTime.fromISO(props.eventTimeCreated, {
        zone: "utc",
      });
      await Promise.all([
        dispatch(setSelectedUnit(props.unitID)),
        dispatch(
          expandAnalyticsChart({
            chartType: AnalyticsChartTypes.activity,
            endTime: eventTime,
            customZoom: getZoomByEventType(props.eventType),
          })
        ),
      ]);
      await dispatch(getUnitChartData(props.unitID));
    }
    history.push(Views.ANALYTICS);
  };

  return (
    <div className={classes.container}>
      <Grid container className={classes.sectionTitle}>
        <Grid item xs={11}>
          <SectionHeader
            text={props.title}
            complementaryText={
              props.complementaryText !== ""
                ? props.complementaryText
                : undefined
            }
          />
        </Grid>
        <Grid item xs={1} className={classes.iconBox}>
          <div>
            <IconButton
              onClick={analyticsRedirect}
              className={classes.iconButton}
            >
              {
                <img
                  src={Icons.AnalyticsShortcut}
                  alt={t("analytics")}
                  draggable={false}
                  className={classes.icon}
                />
              }
            </IconButton>
          </div>
        </Grid>
      </Grid>
      <div
        className={classes.containerA}
        style={{
          height: `calc(100% - ${props.emptyData ? `31` : `21`}px${
            props.endText !== undefined && props.endText !== "" ? ` - 25px` : ""
          })`,
        }}
      >
        {props.children}
      </div>
      {props.endText && (
        <div className={classes.centerSelf}>{props.endText}</div>
      )}
    </div>
  );
};
