import { Grid, IconButton, List, withStyles } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useAppDispatch, useAppSelector } from "../app/appHooks";
import MuiListItem from "@material-ui/core/ListItem";
import Icons from "../../helpers/iconImports";
import { useTranslation } from "react-i18next";
import { RoleTypes, SettingListKeys } from "../../helpers/constants";
import React from "react";
import { setSettingsOption } from "./settingsSlice";
import { getDropdownList } from "../header/CommunityDropdown";
import { UsersSettingsList } from "./UsersSettingsList";
import { NotificationGroupsList } from "./NotificationGroupsList";
import { NotificationSettingsList } from "./NotificationSettingsList";
import { ResidentUnitsList } from "./ResidentUnitsList";
import { isHelpAtHome } from "../dashboard/dashboardSlice";

const useStyles = <T extends { containerWidth: number }>(props: T) =>
  makeStyles((theme: Theme) =>
    createStyles({
      settingsContainer: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: `calc(100% / ${props.containerWidth})`,
        background: theme.palette.light.main,
        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        padding: theme.spacing(3, 0),
      },
      listContainer: {
        background: "#FFF",
      },
      profileContainer: {
        width: "100%",
        overflowY: "auto",
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
      },
      optionsContianer: {
        width: "100%",
        overflowY: "auto",
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
      },
      itemLabelContainer: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
      },
      settingsIcon: {
        height: "23px",
        width: "23px",
      },
      iconButtonRoot: {
        display: "flex",
        padding: theme.spacing(0, 1),
        "&:hover": {
          backgroundColor: `unset`,
        },
      },
      secondaryTextContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      },
      settingsTitle: {
        fontFamily: theme.typography.tertiaryFontFamily,
        fontSize: "16px",
        paddingLeft: theme.spacing(1),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(1),
      },
    })
  );

export const SettingsListItem = withStyles(({ palette, spacing }) => ({
  root: {
    paddingBottom: spacing(1),
    paddingTop: spacing(1),
    width: "100%",
    borderRadius: "0px",
    "&$selected": {
      backgroundColor: `${palette.primary.main}4D !important `,
      fontWeight: "bold",
    },
    "&$selected:hover": {
      backgroundColor: palette.primary.main,
    },
    "&:hover": {
      backgroundColor: `${palette.primary.main}4D !important `,
    },
  },
  selected: {},
  divider: {},
}))(MuiListItem);

const menuItems = [
  {
    name: SettingListKeys.community_info,
    icon: Icons.CommunitySettings,
    key: SettingListKeys.community_info,
  },
  {
    name: SettingListKeys.community_users,
    icon: Icons.CommunityUsers,
    key: SettingListKeys.community_users,
  },
  {
    name: SettingListKeys.notification_groups,
    icon: Icons.NotificationGroups,
    key: SettingListKeys.notification_groups,
  },
  {
    name: SettingListKeys.notification_settings,
    icon: Icons.NotificationSettings,
    key: SettingListKeys.notification_settings,
  },
  {
    name: SettingListKeys.hand_off,
    icon: Icons.HandOffSettings,
    key: SettingListKeys.hand_off,
  },
  {
    name: SettingListKeys.units_residents,
    icon: Icons.UnitsResidents,
    key: SettingListKeys.units_residents,
  },
];

const installerMenuItems = [
  {
    name: SettingListKeys.units_residents,
    icon: Icons.UnitsResidents,
    key: SettingListKeys.units_residents,
  },
];

const filterMenuItems = (
  menuOptions: typeof menuItems,
  options: { someHelpAtHome: boolean | undefined }
) => {
  return (
    menuOptions
      // If the community is help at home, show hand off option
      .filter((option) =>
        option.key === SettingListKeys.hand_off
          ? !!options.someHelpAtHome
          : true
      )
  );
};

const orgMenuItems = [
  {
    name: SettingListKeys.organization_info,
    icon: Icons.OrganizationSettings,
    key: SettingListKeys.organization_info,
  },
  {
    name: SettingListKeys.organization_users,
    icon: Icons.CommunityUsers,
    key: SettingListKeys.organization_users,
  },
  {
    name: SettingListKeys.organization_groups,
    icon: Icons.CommunitySettings,
    key: SettingListKeys.organization_groups,
  },
];

export function SettingsList() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  /* Selectors */
  const selectedOption = useAppSelector(
    (state) => state.settingsState.selectedOption
  );

  const communities = useAppSelector((state) => state.headerState.communities);

  const selectedCommunity = useAppSelector(
    (state) => state.headerState.selectedCommunity
  );

  const selectedOrganizationID = useAppSelector(
    (state) => state.headerState.selectedOrganization
  );
  const selectedUnit = useAppSelector(
    (state) => state.settingsState.selectedUnit
  );

  const someHelpAtHome = useAppSelector((state) => {
    if (state.headerState.units === undefined) return false;
    return state.headerState.units.some((unit) => isHelpAtHome(unit));
  });

  const getListContainerWidth = () => {
    switch (selectedOption) {
      case SettingListKeys.community_users:
      case SettingListKeys.notification_groups:
      case SettingListKeys.notification_settings:
        return 2;
      case SettingListKeys.units_residents:
        if (selectedUnit) return 3;
        return 2;
      default:
        return 1;
    }
  };
  const classes = useStyles({ containerWidth: getListContainerWidth() })();

  // Dropdown list puts together a list which filters according to role,
  const dropdownList = getDropdownList(communities ?? []);

  let organization = undefined;

  dropdownList.find((item) => item.id === selectedOrganizationID);

  // Determine the role of the user
  const isCommunityAdmin = !!selectedCommunity?.role.includes(RoleTypes.admin);
  let isOrgAdmin = false;
  const isInstaller = !!selectedCommunity?.role.includes(RoleTypes.installer);
  if (selectedCommunity) {
    isOrgAdmin = selectedCommunity.role.includes(RoleTypes.org);
    organization = { name: selectedCommunity.organization_name };
  } else {
    organization = dropdownList.find(
      (item) => item.id === selectedOrganizationID
    );
  }

  const showCommunityList =
    selectedCommunity !== undefined
      ? isOrgAdmin || isCommunityAdmin || isInstaller
      : false;

  const showOrganizationList = false;
  /* Logic to determine if the organization list should be displayed

    selectedCommunity !== undefined? isOrgAdmin: selectedOrganizationID !== undefined
      ? !!organization
      : false; 
    */

  /* Methods */
  const handleSelectOption = (key: string) => {
    dispatch(setSettingsOption(key));
  };

  const filteredMenuItems = isInstaller
    ? installerMenuItems
    : filterMenuItems(menuItems, { someHelpAtHome });

  return (
    <React.Fragment>
      <div className={classes.settingsContainer}>
        <List className={classes.profileContainer}>
          <SettingsListItem
            key="my_profile"
            button
            divider
            onClick={() => handleSelectOption("my_profile")}
            selected={"my_profile" === selectedOption}
            disableGutters
            className={classes.listContainer}
          >
            <Grid container className={classes.itemLabelContainer}>
              <Grid item xs={5}>
                <div className={classes.secondaryTextContainer}>
                  <IconButton
                    classes={{
                      root: classes.iconButtonRoot,
                    }}
                  >
                    <img
                      src={Icons.CareUser}
                      alt={""}
                      draggable={false}
                      className={classes.settingsIcon}
                    />
                  </IconButton>
                  {t("my_profile")}
                </div>
              </Grid>
            </Grid>
          </SettingsListItem>
        </List>
        {showCommunityList && (
          <React.Fragment>
            <div className={classes.settingsTitle}>
              {selectedCommunity?.name ?? ""}
            </div>
            <List className={classes.optionsContianer}>
              {filteredMenuItems.map((item) => (
                <SettingsListItem
                  key={item.key}
                  button
                  divider
                  disableGutters
                  onClick={() => handleSelectOption(item.key)}
                  selected={item.key === selectedOption}
                  className={classes.listContainer}
                >
                  <Grid container className={classes.itemLabelContainer}>
                    <Grid item xs={12}>
                      <div className={classes.secondaryTextContainer}>
                        <IconButton
                          classes={{
                            root: classes.iconButtonRoot,
                          }}
                        >
                          <img
                            src={item.icon}
                            alt={""}
                            draggable={false}
                            className={classes.settingsIcon}
                          />
                        </IconButton>
                        {t(item.name)}
                      </div>
                    </Grid>
                  </Grid>
                </SettingsListItem>
              ))}
            </List>
          </React.Fragment>
        )}

        {showOrganizationList && (
          <React.Fragment>
            <div className={classes.settingsTitle}>{organization?.name}</div>
            <List className={classes.optionsContianer}>
              {orgMenuItems.map((item) => (
                <SettingsListItem
                  button
                  key={item.key}
                  divider
                  disableGutters
                  onClick={() => handleSelectOption(item.key)}
                  selected={item.key === selectedOption}
                  className={classes.listContainer}
                >
                  <Grid container className={classes.itemLabelContainer}>
                    <Grid item xs={12}>
                      <div className={classes.secondaryTextContainer}>
                        <IconButton
                          classes={{
                            root: classes.iconButtonRoot,
                          }}
                        >
                          <img
                            src={item.icon}
                            alt={""}
                            draggable={false}
                            className={classes.settingsIcon}
                          />
                        </IconButton>
                        {t(item.name)}
                      </div>
                    </Grid>
                  </Grid>
                </SettingsListItem>
              ))}
            </List>
          </React.Fragment>
        )}
      </div>
      <UsersSettingsList />
      <NotificationGroupsList />
      <NotificationSettingsList />
      <ResidentUnitsList />
    </React.Fragment>
  );
}
