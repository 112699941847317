import {
  IBathroomEventArray,
  ITemperatureData,
  IBathroomEventData,
  IMonitorData,
  IFallData,
} from "./eventDataTypes";
import i18n from "../../i18n";
import {
  capitalize,
  EventThresholds,
  EventTypes,
  formatTemperature,
  TimeFormat,
  truncate,
} from "../../helpers/constants";
import {
  DateTimeISO,
  DateTimeShortFormatAlt,
  timeConfigurations,
  TimeShortFormat,
  TimeShortFormatNoTZ,
} from "../../helpers/datetime";
import { Theme } from "@material-ui/core/styles";

const maxMinutes = 100;

interface IEventValue {
  value: string;
  complementText: string;
}

/**
 * Returns a detail string by event type
 *
 * @param eventType - The event type
 * @param data - A string that can be parsed to obtain event data
 * @returns A string with the detailed information for each event
 *
 */
export const detailsByEventType = (
  eventType: string,
  data: string,
  timeCreated?: string,
  communityTimezone?: string
) => {
  if (data === "") return "";
  switch (eventType) {
    case EventTypes.bathroom_visit_count:
      return getBVC(data);
    case EventTypes.help_button_press:
      return i18n.t("pressed").toUpperCase();
    case EventTypes.heat_index_warning:
      return getThresholdTemp(data);
    case EventTypes.night_bathroom_aggregate_time:
    case EventTypes.night_bathroom_overstay:
    case EventTypes.night_bathroom_visit_count:
      return getNightBathroomSummary(eventType, data);
    case EventTypes.no_morning_motion:
      return i18n.t("late").toUpperCase();
    case EventTypes.activity_monitor_night:
      return getNightActivityMonitorSummary(data);
    case EventTypes.activity_monitor_day:
      return getDayActivityMonitorSummary(data);
    case EventTypes.exterior_exit:
      return i18n.t("opened").toUpperCase();
    case EventTypes.site_exit:
      return i18n.t("exited").toUpperCase();
    case EventTypes.pause_notification:
      return i18n.t("paused").toUpperCase();
    case EventTypes.security_notification:
      return i18n.t("activity_detected").toUpperCase();
    case EventTypes.bathroom_anomaly:
      return getBathroomAnomalySummary(data);
    case EventTypes.bathroom_entry:
      return i18n.t("entry_detected").toUpperCase();
    case EventTypes.activity_monitor_kitchen:
      return getKitchenMonitorSummary(data);
    case EventTypes.refrigerator_door_open:
      return getRefrigeratorDoorSummary(data);
    case EventTypes.exterior_door_open:
      return getOpenDoorSummary(data);
    case EventTypes.hand_off_notification:
      return getHandOffSummary(timeCreated);
    case EventTypes.fall_notification:
      return getFallNotificationSummary(data, communityTimezone);
    default:
      return "";
  }
};

/**
 * Returns a summary text used in the tab tray details
 *
 * @param eventType - The event type
 * @param data - A string that can be parsed to obtain event data
 * @returns A string describing succinctly each event type
 *
 */
export const summaryByEventType = (eventType: string, data: string) => {
  switch (eventType) {
    case EventTypes.bathroom_visit_count:
      return i18n.t("high_count").toUpperCase();
    case EventTypes.help_button_press:
      return i18n.t("pressed").toUpperCase();
    case EventTypes.heat_index_warning:
      const temperatureData: ITemperatureData = JSON.parse(data);
      const thresholdValue = temperatureData?.threshold;
      let summaryText = "";
      if (thresholdValue) {
        // Get system unit
        if (thresholdValue === EventThresholds.LOWER) {
          summaryText = i18n.t("low_temp");
        } else if (thresholdValue === EventThresholds.UPPER) {
          summaryText = i18n.t("high_temp");
        }
      }
      return summaryText.toUpperCase();
    case EventTypes.night_bathroom_aggregate_time:
      return i18n.t("total_time").toUpperCase();
    case EventTypes.night_bathroom_overstay:
      return i18n.t("long_visit").toUpperCase();
    case EventTypes.night_bathroom_visit_count:
      return i18n.t("total_visits").toUpperCase();
    case EventTypes.bathroom_entry:
      return i18n.t("entry_detected").toUpperCase();
    case EventTypes.no_morning_motion:
      return i18n.t("late").toUpperCase();
    case EventTypes.activity_monitor_night:
      return i18n.t("inactive").toUpperCase();
    case EventTypes.activity_monitor_day:
      return i18n.t("inactive").toUpperCase();
    case EventTypes.exterior_exit:
      return i18n.t("opened").toUpperCase();
    case EventTypes.site_exit:
      return i18n.t("exited").toUpperCase();
    case EventTypes.bathroom_anomaly:
      return i18n.t("long_visit").toUpperCase();
    case EventTypes.pause_notification:
      return i18n.t("paused").toUpperCase();
    case EventTypes.security_notification:
      return i18n.t("activity_detected").toUpperCase();
    case EventTypes.activity_monitor_kitchen:
      return i18n.t("inactive").toUpperCase();
    case EventTypes.refrigerator_door_open:
      return i18n.t("open").toUpperCase();
    case EventTypes.exterior_door_open:
      return i18n.t("open").toUpperCase();
    case EventTypes.hand_off_notification:
      return i18n.t("effective").toUpperCase();
    case EventTypes.fall_notification:
      return i18n.t("fall_detected").toUpperCase();
    default:
      return "";
  }
};

/**
 * Returns a localized helper text by event type used in the tab tray details
 *
 * @param eventType - The event type
 * @param creationTime - The event creation time
 * @param data - A string that can be parsed to obtain event data
 * @returns A string with a helper text that can contain additional information by event type
 *
 */
export const helperTextByEventType = (
  eventType: string,
  creationTime: string,
  data: string,
  zoneId: string,
  communityTimezone?: string
) => {
  switch (eventType) {
    case EventTypes.bathroom_visit_count:
      return i18n.t("bathroom_detail_summary_total_cluster");
    case EventTypes.help_button_press:
      return getHelpButtonHelperText(creationTime, data, communityTimezone);
    case EventTypes.heat_index_warning:
      return getHeatIndexHelperText(data);
    case EventTypes.night_bathroom_aggregate_time:
      return i18n.t("night_bathroom_aggregate_time_warning_message");
    case EventTypes.night_bathroom_overstay:
      return i18n.t("night_bathroom_overstay_warning_message");
    case EventTypes.night_bathroom_visit_count:
      return i18n.t("night_bathroom_visit_count_warning_message");
    case EventTypes.bathroom_entry:
      return getBathroomActivityHelperText(data, zoneId, communityTimezone);
    case EventTypes.no_morning_motion:
      return i18n.t("up_and_about_summary");
    case EventTypes.activity_monitor_night:
    case EventTypes.activity_monitor_day:
      return getActivityMonitorHelperText(eventType, data, communityTimezone);
    case EventTypes.exterior_exit:
      return getExteriorExitHelperText(data, communityTimezone);
    case EventTypes.site_exit:
      return getSiteExitHelperText(creationTime, communityTimezone);
    case EventTypes.pause_notification:
      return getPausedNotificationHelperText(creationTime, communityTimezone);
    case EventTypes.security_notification:
      return getSecurityNotificationHelperText(data, communityTimezone);
    case EventTypes.bathroom_anomaly:
      return i18n.t("bathroom_overstay_summary");
    case EventTypes.activity_monitor_kitchen:
      return getKitchenMonitorHelperText(data, communityTimezone);
    case EventTypes.refrigerator_door_open:
      return getRefrigeratorOpenHelperText(data);
    case EventTypes.exterior_door_open:
      return getDoorOpenHelperText(data);
    case EventTypes.hand_off_notification:
      return getHandOffHelperText(creationTime, communityTimezone);
    case EventTypes.fall_notification:
      return getFallNotificationHelperText(data, communityTimezone);
    default:
      return "";
  }
};

export const eventValueByEventType = (
  eventType: string,
  creationTime: string,
  data: string,
  communityTimezone?: string
): IEventValue => {
  switch (eventType) {
    case EventTypes.bathroom_visit_count:
      return getBathroomVisitCountValue(data);
    case EventTypes.help_button_press:
    case EventTypes.pause_notification:
    case EventTypes.security_notification:
      return getHelpButtonDetailValue(creationTime, communityTimezone);
    case EventTypes.heat_index_warning:
      return getHeatIndexWarningValue(data);
    case EventTypes.night_bathroom_aggregate_time:
    case EventTypes.night_bathroom_overstay:
    case EventTypes.night_bathroom_visit_count:
      return getNightBathroomValue(eventType, data);
    case EventTypes.no_morning_motion:
      return getUpAndAboutDetailValue(data);
    case EventTypes.activity_monitor_night:
    case EventTypes.activity_monitor_day:
      return getActivityMonitorValue(data);
    case EventTypes.exterior_exit:
      return getExteriorExitValue(data, communityTimezone);
    case EventTypes.site_exit:
      return getSiteExitValue(creationTime, communityTimezone);
    case EventTypes.bathroom_anomaly:
      return getBathhroomAnomalyValue(data);
    case EventTypes.activity_monitor_kitchen:
      return getKitchenMonitorValue(data);
    case EventTypes.refrigerator_door_open:
      return getRefrigeratorDoorOpenValue(data);
    case EventTypes.exterior_door_open:
      return getDoorOpenValue(data);
    case EventTypes.hand_off_notification:
      return getHandOffValue(creationTime, communityTimezone);
    case EventTypes.bathroom_entry:
      return getBathroomActivityValue(creationTime, data, communityTimezone);
    case EventTypes.fall_notification:
      return getFallNotificationValue(creationTime, data, communityTimezone);
    default:
      return { value: "", complementText: "" };
  }
};

export const detailColorByEventType = (
  eventType: string,
  theme: Theme
): string => {
  switch (eventType) {
    case EventTypes.pause_notification:
      return theme.palette.paused.contrastText;
    case EventTypes.bathroom_visit_count:
    case EventTypes.hand_off_notification:
      return theme.palette.warning.contrastText;
    default:
      return theme.palette.error.contrastText;
  }
};

export const shouldShowIconByEventType = (eventType: string): boolean => {
  switch (eventType) {
    case EventTypes.pause_notification:
      return false;
    default:
      return true;
  }
};

const getBVCTotalVisits = (bathroomData: IBathroomEventArray) => {
  const sortedBathroomData = bathroomData.data?.sort(sortBathroomArray);

  const visitCount = sortedBathroomData.find(
    (element) => element.total !== undefined && element.total !== null
  );
  if (
    visitCount &&
    visitCount !== null &&
    visitCount.total &&
    visitCount.total !== null &&
    visitCount.total.visits &&
    visitCount.total.visits !== null
  ) {
    return visitCount.total.visits;
  } else {
    return undefined;
  }
};

// Obtains details for bathroom_visit_count event
const getBVC = (data: string): string | undefined => {
  const bathroomData: IBathroomEventArray = JSON.parse(data);
  const visitCount = getBVCTotalVisits(bathroomData);

  if (visitCount !== undefined) {
    const highCountTxt = i18n.t("high_count").toUpperCase();
    return `${highCountTxt}: ${visitCount}`;
  } else {
    return undefined;
  }
};

// Obtains details for heat_index_warning event
const getThresholdTemp = (data: string): string | undefined => {
  const temperatureData: ITemperatureData = JSON.parse(data);
  const thresholdValue = temperatureData?.threshold;
  const temperature = temperatureData?.heat_index;
  if (thresholdValue && temperature) {
    const value = formatTemperature(temperature);
    // Get system unit
    if (thresholdValue === EventThresholds.LOWER) {
      const lowTempTxt = i18n.t("low_temp").toUpperCase();
      return `${lowTempTxt}: ${value}`;
    } else if (thresholdValue === EventThresholds.UPPER) {
      const highTempTxt = i18n.t("high_temp").toUpperCase();
      return `${highTempTxt}: ${value}`;
    }
  }
  return undefined;
};

// Obtains details for night_bathroom_aggregate_time, night_bathroom_overstay, night_bathroom_visit_count events
const getNightBathroomSummary = (
  eventType: string,
  data: string
): string | undefined => {
  const nightBathroomData: IMonitorData = JSON.parse(data);
  if (nightBathroomData) {
    let triggeringValue = "-";
    if (nightBathroomData.triggering_value) {
      triggeringValue = String(nightBathroomData.triggering_value);
    }
    const formatedTriggeringValue =
      nightBathroomData.triggering_value !== undefined &&
      nightBathroomData.triggering_value !== null
        ? valueFormater(nightBathroomData.triggering_value, true)
        : "-";
    let valueToDisplay = "";
    switch (eventType) {
      case EventTypes.night_bathroom_aggregate_time:
        let totalTime = i18n.t("total_time").toUpperCase();
        valueToDisplay = `${totalTime}: ${formatedTriggeringValue}`;
        break;
      case EventTypes.night_bathroom_overstay:
        let longVisit = i18n.t("long_visit").toUpperCase();
        valueToDisplay = `${longVisit}: ${formatedTriggeringValue}`;
        break;
      case EventTypes.night_bathroom_visit_count:
        let visits = i18n.t("total_visits").toUpperCase();
        valueToDisplay = `${visits}: ${triggeringValue}`;
        break;
      default:
        valueToDisplay = "";
        break;
    }
    return valueToDisplay;
  }
  return "";
};

// Obtains details for bathroom anomaly
const getBathroomAnomalySummary = (data: string): string | undefined => {
  const bathroomData: IMonitorData = JSON.parse(data);
  if (bathroomData) {
    const formatedTriggeringValue =
      bathroomData.triggering_value !== undefined &&
      bathroomData.triggering_value !== null
        ? valueFormater(bathroomData.triggering_value, true)
        : "-";
    let valueToDisplay = "";

    let longVisit = i18n.t("long_visit").toUpperCase();
    valueToDisplay = `${longVisit}: ${formatedTriggeringValue}`;
    return valueToDisplay;
  }
  return "";
};

// Obtains details for activity_monitor_night event
const getNightActivityMonitorSummary = (data: string): string | undefined => {
  const parsedDetails: IMonitorData = JSON.parse(data);
  if (parsedDetails !== undefined) {
    if (parsedDetails.triggering_value !== undefined) {
      let inactive = i18n.t("inactive").toUpperCase();
      const formatedTriggeringValue =
        parsedDetails.triggering_value !== undefined &&
        parsedDetails.triggering_value !== null
          ? valueFormater(parsedDetails.triggering_value, true)
          : "-";
      return `${inactive}: ${formatedTriggeringValue}`;
    }
  }
  return "";
};

// Obtains details for activity_monitor_day event
const getDayActivityMonitorSummary = (data: string): string | undefined => {
  const parsedDetails: IMonitorData = JSON.parse(data);
  if (parsedDetails !== undefined) {
    if (parsedDetails.triggering_value !== undefined) {
      let inactive = i18n.t("inactive").toUpperCase();
      const formatedTriggeringValue =
        parsedDetails.triggering_value !== undefined &&
        parsedDetails.triggering_value !== null
          ? valueFormater(parsedDetails.triggering_value, true)
          : "-";
      return `${inactive}: ${formatedTriggeringValue}`;
    }
  }
  return "";
};

// Obtains details for activity_monitor_kitchen event
const getKitchenMonitorSummary = (data: string): string | undefined => {
  const parsedDetails: IMonitorData = JSON.parse(data);
  if (parsedDetails !== undefined) {
    if (parsedDetails.triggering_value !== undefined) {
      let inactive = i18n.t("inactive").toUpperCase();
      const formatedTriggeringValue =
        parsedDetails.triggering_value !== undefined &&
        parsedDetails.triggering_value !== null
          ? valueFormater(parsedDetails.triggering_value, true)
          : "-";
      return `${inactive}: ${formatedTriggeringValue}`;
    }
  }
  return "";
};

const getRefrigeratorDoorSummary = (data: string): string | undefined => {
  const parsedDetails: IMonitorData = JSON.parse(data);
  if (parsedDetails !== undefined && parsedDetails.triggering_value) {
    let open = i18n.t("open").toUpperCase();
    const formatedTriggeringValue = valueFormater(
      parsedDetails.triggering_value,
      true
    );
    return `${open}: ${formatedTriggeringValue || "-"}`;
  }
  return "";
};

const getOpenDoorSummary = (data: string): string | undefined => {
  const parsedDetails: IMonitorData = JSON.parse(data);
  if (parsedDetails !== undefined && parsedDetails.triggering_value) {
    let open = i18n.t("open").toUpperCase();
    const formatedTriggeringValue = valueFormater(
      parsedDetails.triggering_value,
      true
    );
    return `${open}: ${formatedTriggeringValue || "-"}`;
  }
  return "";
};

const getHandOffSummary = (timeCreated?: string): string | undefined => {
  if (timeCreated !== undefined) {
    let effective = i18n.t("effective").toUpperCase();
    const timeCreatedText = TimeShortFormat(timeCreated);
    return `${effective}: ${timeCreatedText}`;
  }
  return undefined;
};

const getFallNotificationSummary = (
  data: string,
  communityTimezone?: string
): string | undefined => {
  if (communityTimezone !== undefined) {
    const fallData: IMonitorData = JSON.parse(data);
    let fallDetected = i18n.t("fall_detected").toUpperCase();
    if (fallData.triggering_time) {
      const timeCreatedText = TimeShortFormat(
        fallData.triggering_time,
        communityTimezone
      );
      fallDetected = `${fallDetected}: ${timeCreatedText}`;
    }
    return fallDetected;
  }
  return undefined;
};

const getHelpButtonHelperText = (
  creationTime: string,
  data: string,
  communityTimezone?: string
): string => {
  const monitorData: IMonitorData = JSON.parse(data);
  const lastZone = monitorData?.last_motion_zone_name;
  const lastMotionTime = monitorData?.last_motion_event_time;

  let creationText = "-";
  if (creationTime) {
    creationText = DateTimeShortFormatAlt(creationTime, communityTimezone);
  }

  let helperText = "";
  helperText = i18n.t("help_button_pressed", { pressedTime: creationText });

  // Check if we have to include last motion text
  if (lastMotionTime) {
    // We have values for last motion time and zone
    if (lastZone) {
      helperText = `${helperText} ${i18n.t("last_activity_seen_in", {
        zone: lastZone,
        lastMotion: DateTimeShortFormatAlt(lastMotionTime),
      })}`;
    }
    // Only value for last motion time
    else {
      helperText = `${helperText} ${i18n.t("last_time_activity_summary", {
        lastMotion: DateTimeShortFormatAlt(lastMotionTime),
      })}`;
    }
  }
  return helperText;
};

const getHeatIndexHelperText = (data: string): string => {
  const temperatureData: ITemperatureData = JSON.parse(data);
  const thresholdValue = temperatureData?.threshold;
  let tempHelperText = "";
  if (thresholdValue) {
    const dailyTemps = temperatureData.daily_temps;
    if (thresholdValue === EventThresholds.LOWER) {
      const highThreshold = temperatureData.typical_chart.typ_low_2;
      let lowZones: string[] = [];
      dailyTemps.forEach((temp) => {
        if (
          Number(temp.heat_index ? temp.heat_index : temp.current) <=
          Number(highThreshold)
        ) {
          lowZones.push(temp.name);
        }
      });
      tempHelperText = i18n.t("temperature_is_low_in", {
        zoneName: concatArray(lowZones),
      });
    } else if (thresholdValue === EventThresholds.UPPER) {
      const highThreshold = temperatureData.typical_chart.typ_high_2;
      let highZones: string[] = [];
      dailyTemps.forEach((temp) => {
        console.log(temp.heat_index);
        if (
          Number(temp.heat_index ? temp.heat_index : temp.current) >=
          Number(highThreshold)
        ) {
          highZones.push(temp.name);
        }
      });
      tempHelperText = i18n.t("temperature_is_high_in", {
        zoneName: concatArray(highZones),
      });
    }
    return tempHelperText;
  }
  return "";
};

const getActivityMonitorHelperText = (
  eventType: string,
  data: string,
  communityTimezone?: string
): string | undefined => {
  const monitorData: IMonitorData = JSON.parse(data);
  const triggeringValue = monitorData?.triggering_value;
  const lastZone = monitorData?.last_motion_zone_name;
  const lastMotionTime = monitorData?.last_motion_event_time;
  let helperText = "";

  // Format triggering value
  let durationText = "-";
  if (triggeringValue) {
    durationText = valueFormater(triggeringValue, true, true);
  }
  // Get text according to event type (night or day)
  if (eventType === EventTypes.activity_monitor_day) {
    helperText = i18n.t("day_activity_summary", { duration: durationText });
  } else if (eventType === EventTypes.activity_monitor_night) {
    helperText = i18n.t("night_activity_summary", {
      duration: durationText,
    });
  }
  // Check if we have to include last motion text
  if (lastMotionTime) {
    // We have values for last motion time and zone
    if (lastZone) {
      helperText = `${helperText} ${i18n.t("last_activity_summary", {
        zone: lastZone,
        lastMotion: DateTimeShortFormatAlt(lastMotionTime, communityTimezone),
      })}`;
    }
    // Only value for last motion time
    else {
      helperText = `${helperText} ${i18n.t("last_activity_was_on", {
        lastMotion: DateTimeShortFormatAlt(lastMotionTime, communityTimezone),
      })}`;
    }
  }
  return helperText;
};

const getKitchenMonitorHelperText = (
  data: string,
  communityTimezone?: string
): string | undefined => {
  const monitorData: IMonitorData = JSON.parse(data);
  const lastMotionTime = monitorData?.last_motion_event_time;

  const helperText = `${i18n.t("activity_monitor_kitchen_summary", {
    lastMotion: lastMotionTime
      ? DateTimeShortFormatAlt(lastMotionTime, communityTimezone)
      : "-",
  })}`;

  return helperText;
};

const getBathroomActivityHelperText = (
  data: string,
  zoneId: string,
  communityTimezone?: string
): string | undefined => {
  const monitorData: IMonitorData = JSON.parse(data);
  const triggeringTime = monitorData?.triggering_time;
  const zoneName =
    monitorData?.activity_data?.find((d) => d.zone_id === zoneId)?.zone_name ||
    "-";

  const helperText = `${i18n.t("bathroom_entry_summary", {
    zoneName: zoneName,
    triggeringTime: triggeringTime
      ? TimeShortFormat(triggeringTime, communityTimezone)
      : "-",
  })}`;

  return helperText;
};

const getRefrigeratorOpenHelperText = (data: string): string | undefined => {
  const monitorData: IMonitorData = JSON.parse(data);
  const triggeringValue = monitorData?.triggering_value;

  let complementText = "";
  // Format triggering value
  let durationText = "-";
  if (triggeringValue) {
    durationText = valueFormater(triggeringValue);
    if (triggeringValue >= maxMinutes) {
      complementText = i18n.t("hours");
    } else {
      complementText = i18n.t("minutes");
    }
  }

  const helperText = `${i18n.t("refrigerator_door_open_summary", {
    duration: durationText,
    timeUnit: complementText,
  })}`;

  return helperText;
};

const getDoorOpenHelperText = (data: string): string | undefined => {
  const monitorData: IMonitorData = JSON.parse(data);
  const triggeringValue = monitorData?.triggering_value;

  let complementText = "";
  // Format triggering value
  let durationText = "-";
  if (triggeringValue) {
    durationText = valueFormater(triggeringValue);
    if (triggeringValue >= maxMinutes) {
      complementText = i18n.t("hours");
    } else {
      complementText = i18n.t("minutes");
    }
  }

  const helperText = `${i18n.t("exterior_door_open_summary", {
    duration: durationText,
    timeUnit: complementText,
  })}`;

  return helperText;
};

const getExteriorExitHelperText = (
  data: string,
  communityTimezone?: string
): string | undefined => {
  const monitorData: IMonitorData = JSON.parse(data);

  const lastContactEventTime = monitorData?.last_contact_event_time;
  const lastContactZone = monitorData?.last_contact_zone_name;

  const lastZone = monitorData?.last_motion_zone_name;
  const lastMotionTime = monitorData?.last_motion_event_time;

  let helperText = "";

  // Get text according to event type (night or day)
  let lastContactTimeText = "-";
  let lastContactZoneText = "-";
  if (lastContactEventTime) {
    lastContactTimeText = DateTimeShortFormatAlt(
      lastContactEventTime,
      communityTimezone
    );
  }
  if (lastContactZone) {
    lastContactZoneText = lastContactZone;
  }

  helperText = i18n.t("exterior_door_last_contact", {
    zone: lastContactZoneText,
    time: lastContactTimeText,
  });

  // Check if we have to include last motion text
  if (lastMotionTime) {
    // We have values for last motion time and zone
    if (lastZone) {
      helperText = `${helperText} ${i18n.t("last_activity_seen_in", {
        zone: lastZone,
        lastMotion: DateTimeShortFormatAlt(lastMotionTime, communityTimezone),
      })}`;
    }
    // Only value for last motion time
    else {
      helperText = `${helperText} ${i18n.t("last_activity_was_seen_on", {
        lastMotion: DateTimeShortFormatAlt(lastMotionTime, communityTimezone),
      })}`;
    }
  }
  return helperText;
};

const getSecurityNotificationHelperText = (
  data: string,
  communityTimezone?: string
): string | undefined => {
  const monitorData: IMonitorData = JSON.parse(data);

  const lastZone = monitorData?.last_motion_zone_name;
  const lastMotionTime = monitorData?.last_motion_event_time;

  let helperText = "";

  // Check if we have to include last motion text
  if (lastMotionTime) {
    // We have values for last motion time and zone
    if (lastZone) {
      helperText = `${helperText} ${i18n.t("security_notification_summary", {
        zone: lastZone,
        lastMotion: DateTimeShortFormatAlt(lastMotionTime, communityTimezone),
      })}`;
    }
    // Only value for last motion time
    else {
      helperText = `${helperText} ${i18n.t("activity_first_detected_on", {
        lastMotion: DateTimeShortFormatAlt(lastMotionTime, communityTimezone),
      })}`;
    }
  }
  return helperText;
};

const getSiteExitHelperText = (
  creationTime: string,
  communityTimezone?: string
): string => {
  let helperText = "";
  let creationText = "-";
  if (creationTime) {
    creationText = DateTimeShortFormatAlt(creationTime, communityTimezone);
  }
  helperText = i18n.t("site_exit_description", { time: creationText });
  return helperText;
};

const getPausedNotificationHelperText = (
  creationTime: string,
  communityTimezone?: string
): string => {
  let helperText = "";
  let creationText = "-";
  if (creationTime) {
    creationText = DateTimeShortFormatAlt(creationTime, communityTimezone);
  }
  helperText = i18n.t("pause_notification_summary", { time: creationText });
  return helperText;
};

const getHandOffHelperText = (
  creationTime: string,
  communityTimezone?: string
): string => {
  let helperText = "";
  let creationText = "-";
  if (creationTime) {
    creationText = TimeShortFormat(creationTime, communityTimezone);
  }
  helperText = i18n.t("hand_off_notification_summary", {
    creationTime: creationText,
  });
  return helperText;
};

const getFallNotificationHelperText = (
  data: string,
  communityTimezone?: string
): string | undefined => {
  const fallData: IFallData = JSON.parse(data);
  const fallTime = fallData?.triggering_time;

  const helperText = `${i18n.t("fall_notification_summary", {
    zoneName: fallData.zone_name,
    triggeringTime: fallTime
      ? DateTimeShortFormatAlt(fallTime, communityTimezone)
      : "-",
  })}`;
  return helperText;
};

// Concatenates an array with separators and localized 'and' for the last item
const concatArray = (zones: string[]): string => {
  let zonesString = "";
  if (zones.length > 1) {
    const orderedZones = zones.sort();
    zonesString = `${orderedZones
      .splice(0, orderedZones.length - 1)
      .join(", ")} ${i18n.t("and_separator")} ${
      orderedZones[orderedZones.length - 1]
    }`;
  } else {
    zonesString = zones.join();
  }
  return zonesString;
};

const getBathroomVisitCountValue = (data: string): IEventValue => {
  const bathroomData: IBathroomEventArray = JSON.parse(data);
  const visitCount = getBVCTotalVisits(bathroomData);

  if (visitCount !== undefined) {
    let formatedValue = "-";
    let complementText = "";
    formatedValue = String(visitCount);
    complementText = i18n.t("visits");
    return { value: formatedValue, complementText: complementText };
  }
  return { value: "-", complementText: "" };
};

const getHelpButtonDetailValue = (
  creationTime: string,
  communityTimezone?: string
): IEventValue => {
  const timeValue = getTimeAndComplement(creationTime, communityTimezone);
  return {
    value: timeValue.value,
    complementText: timeValue.complementText,
  };
};

const getUpAndAboutDetailValue = (data: string): IEventValue => {
  const noMorningMotionData: IMonitorData = JSON.parse(data);
  if (noMorningMotionData) {
    const lateTime = noMorningMotionData.local_time_end;
    if (lateTime) {
      const value = TimeShortFormatNoTZ(lateTime);
      return { value, complementText: "" };
    }
  }
  return { value: "", complementText: "" };
};

const getHeatIndexWarningValue = (data: string): IEventValue => {
  const temperatureData: ITemperatureData = JSON.parse(data);
  const thresholdValue = temperatureData?.threshold;
  const temperature = temperatureData?.heat_index;
  if (thresholdValue && temperature) {
    const value = formatTemperature(temperature);
    // Get system unit
    if (thresholdValue === EventThresholds.LOWER) {
      const complementText = i18n.t("lowest");
      return { value: `${value}`, complementText };
    } else if (thresholdValue === EventThresholds.UPPER) {
      const complementText = i18n.t("highest");
      return { value: `${value}`, complementText };
    }
  }
  return { value: "", complementText: "" };
};

const getNightBathroomValue = (
  eventType: string,
  data: string
): IEventValue => {
  const nightBathroomData: IMonitorData = JSON.parse(data);
  if (nightBathroomData) {
    let formatedValue = "-";
    let complementText = "";
    const triggeringValue = nightBathroomData.triggering_value;

    switch (eventType) {
      case EventTypes.night_bathroom_aggregate_time:
      case EventTypes.night_bathroom_overstay:
        if (triggeringValue) {
          formatedValue = valueFormater(triggeringValue);
          if (triggeringValue >= maxMinutes) {
            complementText = i18n.t("hours");
          } else {
            complementText = i18n.t("minutes");
          }
        }
        return { value: formatedValue, complementText: complementText };
      case EventTypes.night_bathroom_visit_count:
        if (triggeringValue) {
          formatedValue = String(nightBathroomData.triggering_value);
          complementText = capitalize(i18n.t("visits"));
        }
        return { value: formatedValue, complementText: complementText };
      default:
        return { value: "-", complementText: "" };
    }
  }
  return { value: "-", complementText: "" };
};

const getActivityMonitorValue = (data: string): IEventValue => {
  const monitorData: IMonitorData = JSON.parse(data);
  const triggeringValue = monitorData?.triggering_value;
  let formatedValue = "-";
  let complementText = "";
  if (triggeringValue) {
    formatedValue = valueFormater(triggeringValue);
    if (triggeringValue >= maxMinutes) {
      complementText = i18n.t("hours");
    } else {
      complementText = i18n.t("minutes");
    }
    return { value: formatedValue, complementText: complementText };
  }
  return { value: "-", complementText: "" };
};

export const getTimeAndComplement = (
  ISOString: string,
  communityTimezone?: string
) => {
  if (ISOString) {
    const { sameTimeZone, configuredTimeFormat, locale } = timeConfigurations(
      communityTimezone
    );
    const time = DateTimeISO(ISOString, communityTimezone).setLocale(locale);
    const is24H = configuredTimeFormat === TimeFormat.TIME24H;
    return {
      value: is24H ? time.toFormat("HH:mm") : time.toFormat("h:mm"),
      complementText: sameTimeZone
        ? is24H
          ? ""
          : time.toFormat("a")
        : is24H
        ? time.toFormat("ZZZZ")
        : time.toFormat("a',' ZZZZ"),
    };
  } else {
    return { value: "-", complementText: "" };
  }
};

const getExteriorExitValue = (
  data: string,
  communityTimezone?: string
): IEventValue => {
  const monitorData: IMonitorData = JSON.parse(data);
  const lastContactTime = monitorData?.last_contact_event_time;
  if (lastContactTime) {
    const timeValue = getTimeAndComplement(lastContactTime, communityTimezone);

    return {
      value: timeValue.value,
      complementText: timeValue.complementText,
    };
  } else {
    return { value: "-", complementText: "" };
  }
};

const getSiteExitValue = (
  creationTime: string,
  communityTimezone?: string
): IEventValue => {
  const timeValue = getTimeAndComplement(creationTime, communityTimezone);
  return {
    value: timeValue.value,
    complementText: timeValue.complementText,
  };
};

const getBathhroomAnomalyValue = (data: string): IEventValue => {
  const bathroomData: IMonitorData = JSON.parse(data);
  if (bathroomData) {
    let formatedValue = "-";
    let complementText = "";
    const triggeringValue = bathroomData.triggering_value;
    if (triggeringValue) {
      formatedValue = valueFormater(triggeringValue);
      if (triggeringValue >= maxMinutes) {
        complementText = i18n.t("hours");
      } else {
        complementText = i18n.t("minutes");
      }
    }
    return { value: formatedValue, complementText: complementText };
  } else {
    return { value: "-", complementText: "" };
  }
};

const getBathroomActivityValue = (
  creationTime: string,
  data: string,
  communityTimezone?: string
): IEventValue => {
  const monitorData: IMonitorData = JSON.parse(data);
  const triggeringTime = monitorData?.triggering_time;
  let timeValue = getTimeAndComplement(creationTime, communityTimezone);
  if (triggeringTime) {
    timeValue = getTimeAndComplement(triggeringTime, communityTimezone);
  }

  return {
    value: timeValue.value,
    complementText: timeValue.complementText,
  };
};

const getKitchenMonitorValue = (data: string): IEventValue => {
  const monitorData: IMonitorData = JSON.parse(data);
  const triggeringValue = monitorData?.triggering_value;
  let formatedValue = "-";
  let complementText = "";
  if (triggeringValue) {
    formatedValue = valueFormater(triggeringValue);
    if (triggeringValue >= maxMinutes) {
      complementText = i18n.t("hours");
    } else {
      complementText = i18n.t("minutes");
    }
    return { value: formatedValue, complementText: complementText };
  }
  return { value: "-", complementText: "" };
};

const getRefrigeratorDoorOpenValue = (data: string): IEventValue => {
  const monitorData: IMonitorData = JSON.parse(data);
  const triggeringValue = monitorData?.triggering_value;
  let formatedValue = "-";
  let complementText = "";
  if (triggeringValue) {
    formatedValue = valueFormater(triggeringValue);
    if (triggeringValue >= maxMinutes) {
      complementText = i18n.t("hours");
    } else {
      complementText = i18n.t("minutes");
    }
    return { value: formatedValue, complementText: complementText };
  }
  return { value: "-", complementText: "" };
};

const getDoorOpenValue = (data: string): IEventValue => {
  const monitorData: IMonitorData = JSON.parse(data);
  const triggeringValue = monitorData?.triggering_value;
  let formatedValue = "-";
  let complementText = "";
  if (triggeringValue) {
    formatedValue = valueFormater(triggeringValue);
    if (triggeringValue >= maxMinutes) {
      complementText = i18n.t("hours");
    } else {
      complementText = i18n.t("minutes");
    }
    return { value: formatedValue, complementText: complementText };
  }
  return { value: "-", complementText: "" };
};

const getHandOffValue = (
  creationTime: string,
  communityTimezone?: string
): IEventValue => {
  const timeValue = getTimeAndComplement(creationTime, communityTimezone);
  return {
    value: timeValue.value,
    complementText: timeValue.complementText,
  };
};

const getFallNotificationValue = (
  creationTime: string,
  data: string,
  communityTimezone?: string
): IEventValue => {
  const fallData: IFallData = JSON.parse(data);
  let timeValue: { value: string; complementText: string };
  if (fallData.triggering_time) {
    timeValue = getTimeAndComplement(
      fallData.triggering_time,
      communityTimezone
    );
  } else {
    timeValue = getTimeAndComplement(creationTime, communityTimezone);
  }

  return {
    value: timeValue.value,
    complementText: timeValue.complementText,
  };
};

/**
 * Returns a string with formatted minutes according to maxMinutes
 *
 * @param minutes - Minutes to format
 * @param withUnits - Determines if the unit for the minutes or the hours is to be included, `false` by default
 * @param longFormat - Determines which localized string to display, `h` and `m` when `false`, `hour` and `minutes` when `true`
 * , is `false` by default
 * @returns A string with a formatted minutes/hours value to display
 *
 */
export const valueFormater = (
  minutes: number,
  withUnits: boolean = false,
  longFormat: boolean = false
) => {
  let hourStr = "";
  if (withUnits) {
    if (longFormat) {
      hourStr = ` ${i18n.t("hours")}`;
    } else {
      hourStr = ` ${i18n.t("hour_short")}`;
    }
  }
  let minuteString = "";
  if (withUnits) {
    if (longFormat) {
      minuteString = ` ${i18n.t("minutes")}`;
    } else {
      minuteString = ` ${i18n.t("minute_short")}`;
    }
  }
  if (minutes >= maxMinutes) {
    const hours = minutes / 60;
    // Check if its integer or float
    if (hours % 1 === 0) {
      return `${hours}${hourStr}`;
    } else {
      return `${truncate(hours)}${hourStr}`;
    }
  }
  // Display as minutes
  else {
    return `${minutes}${minuteString}`;
  }
};

// Sorts an array of bathroom event data by date, first the most recent one
const sortBathroomArray = (a: IBathroomEventData, b: IBathroomEventData) => {
  const nameA = a.date;
  const nameB = b.date;
  if (nameA < nameB) {
    return 1;
  }
  if (nameA > nameB) {
    return -1;
  }
  // names must be equal
  return 0;
};
