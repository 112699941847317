import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IAppState } from "../../../helpers";
import { TabLabel } from "./TabLabel";
import {
  getResidentCity,
  getResidentName,
  isHelpAtHome,
} from "../dashboardSlice";
import {
  i18textByEventType,
  iconByEventType,
  TabTypes,
} from "../../../helpers/constants";
import CloseIcon from "@material-ui/icons/Close";

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const colorByTabType = (
  tabType: string,
  selected: boolean,
  theme: Theme
) => {
  const unselectedTabBGColor: { [key: string]: string } = {
    [TabTypes.CRITICAL]: theme.palette.error.light,
    [TabTypes.WARNING]: theme.palette.warning.light,
    [TabTypes.UNIT]: theme.palette.normal.trans ?? "",
    [TabTypes.PAUSED]: theme.palette.paused.trans ?? "",
  };
  const selectedTabBGColor: { [key: string]: string } = {
    [TabTypes.CRITICAL]: theme.palette.error.main,
    [TabTypes.WARNING]: theme.palette.warning.main,
    [TabTypes.UNIT]: theme.palette.normal.main,
    [TabTypes.PAUSED]: theme.palette.paused.light,
  };
  if (selected) {
    return selectedTabBGColor[tabType];
  } else {
    return unselectedTabBGColor[tabType];
  }
};

export const TAB_WIDTH = 270;

const useStyles = makeStyles<Theme, IProps>((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      position: "relative",
      color: theme.palette.light.light,
      height: "50px",
      minWidth: "unset",
      width: TAB_WIDTH,
      maxWidth: TAB_WIDTH,
      fontWeight: "normal",
      borderRadius: 0,
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
      fontSize: theme.typography.pxToRem(15),
      borderRight: "1px solid rgba(255, 255, 255, .15)",
      opacity: 1,
      backgroundColor: (props) => {
        return colorByTabType(props.type, props.selected, theme);
      },
    },
    selectedTab: {
      backgroundColor: (props) => {
        return colorByTabType(props.type, props.selected, theme);
      },
    },
    wrapper: {
      flexDirection: "row",
      alignItems: "center",
    },
    critical: {
      backgroundColor: theme.palette.error.main,
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
    },
    unit: {
      backgroundColor: theme.palette.primary.main,
    },
    paused: {
      backgroundColor: theme.palette.paused.light,
    },
    closeButton: {
      position: "absolute",
      top: "4px",
      right: "4px",
    },
    primaryText: {
      fontFamily: theme.typography.secondaryFontFamily,
      whiteSpace: "nowrap",
    },
  })
);

export default function EventTab(props: IProps) {
  const { id, type, index, selected, closeTab, changeTab } = props;
  /* ---- Hooks ---- */
  const classes = useStyles(props);
  const { t } = useTranslation();

  /* ---- Selectors ---- */
  // Obtains the event information
  const event = useSelector((state: IAppState) => {
    return state.dashboardState.communityEvents?.find(
      (event) => event.id === id
    );
  });

  // Obtains the event unit
  const eventUnit = useSelector((state: IAppState) => {
    if (type !== TabTypes.UNIT) {
      const event = state.dashboardState.communityEvents?.find(
        (event) => event.id === id
      );
      if (
        state.headerState.selectedCommunity !== undefined &&
        event !== undefined
      ) {
        return state.dashboardState.units?.find(
          (unit) => unit.id === event.unit_id
        );
      }
      if (
        state.headerState.selectedOrganization !== undefined &&
        event !== undefined &&
        event.community_id
      ) {
        return state.dashboardState.orgUnits[event.community_id]?.find(
          (unit) => unit.id === event.unit_id
        );
      }
      return undefined;
    } else {
      const unit = state.dashboardState.units?.find((unit) => unit.id === id);
      return unit;
    }
  });

  // Get styles according to type
  const eventStatus: TabTypes = type;
  const eventStyle = classes[eventStatus];
  const isHelpAtHomeUnit = isHelpAtHome(eventUnit);
  // Get resident name to display on tab
  const residentName = getResidentName(eventUnit?.residents, {
    fullFirstName: false,
  });

  // Get tab the top label
  let tabDescription = "";
  if (isHelpAtHomeUnit) {
    // Get the resident city
    const residentCity = getResidentCity(eventUnit?.residents);
    tabDescription = `${residentName}${
      residentCity !== "" ? ` - ${residentCity}` : ""
    }`;
  } else {
    tabDescription = `${eventUnit?.name}${
      residentName !== "" ? ` - ${residentName}` : residentName
    }`;
  }
  // Get event display text
  const tabEventDetail =
    type !== "unit" ? t(i18textByEventType(event ? event.event_type : "")) : "";

  // Get icon to display
  const isPausedButton = type === TabTypes.PAUSED;

  const icon = iconByEventType(
    (event && event.event_type) || "",
    isPausedButton
  );

  return (
    <Tab
      className={eventStyle}
      classes={{
        root: classes.root,
        wrapper: classes.wrapper,
        selected: classes.selectedTab,
      }}
      disableRipple={true}
      selected={selected}
      onClick={(e) => {
        e.preventDefault();
        changeTab(index);
      }}
      wrapped={true}
      value={index}
      label={
        <TabLabel
          primaryText={tabDescription}
          secondaryText={tabEventDetail}
          tabType={type}
          icon={icon}
          selected={selected}
          closeIcon={
            <CloseIcon
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                closeTab(index, id);
              }}
              className={classes.closeButton}
              fontSize="small"
            />
          }
        />
      }
      {...a11yProps(index)}
    />
  );
}

interface IProps {
  index: number;
  id: string;
  selected: boolean;
  type: TabTypes;
  closeTab: (index: number, id: string) => void;
  changeTab: (index: number) => void;
}
