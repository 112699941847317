import React from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  Divider,
  List,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";

import MuiListItem from "@material-ui/core/ListItem";
import {
  HelpEventIcon,
  IDropdownListItem,
  NotificationsBubble,
} from "./CommunityDropdown";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: "bolder",
    },
    badgeContent: {
      display: "flex",
    },
    nested: {
      paddingLeft: theme.spacing(5),
    },
    middleNested: {
      marginLeft: theme.spacing(0),
    },
    listTextRoot: {
      paddingRight: theme.spacing(2),
    },
  })
);

// Custom ListItem for Community dropdown
const ListItem = withStyles(({ palette }) => ({
  root: {
    "&$selected": {
      backgroundColor: palette.light.light,
      color: palette.primary.main,
      fontWeight: "bold",
    },
    "&$selected:hover": {
      backgroundColor: palette.primary.main,
      color: palette.light.light,
    },
    "&:hover": {
      backgroundColor: palette.primary.main,
      color: palette.light.light,
    },
  },
  selected: {},
}))(MuiListItem);

export const DropdownListItem = (props: IProps) => {
  const {
    id,
    name,
    helpEvent,
    unreadEvents,
    systemIssues,
    installerRole,
    type,
    sublist,
  } = props.listItem;
  const { selected, nested, handleClick } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const isSelected = selected === id;
  const badgeNumber = installerRole ? systemIssues : unreadEvents;
  return (
    <React.Fragment>
      <ListItem
        button
        selected={isSelected}
        onClick={() => handleClick(id, type)}
        id={`${type}-${id}`}
        className={clsx(nested && classes.nested)}
      >
        <ListItemText
          primaryTypographyProps={{
            classes: {
              root: isSelected ? classes.bold : "",
            },
          }}
          classes={{ root: classes.listTextRoot }}
          primary={name}
        />
        <ListItemSecondaryAction classes={{ root: classes.badgeContent }}>
          {!isSelected &&
            type === "community" &&
            helpEvent !== undefined &&
            helpEvent && (
              <HelpEventIcon
                helpEvent={helpEvent}
                altTextHelp={t("alt_text_community_help_badge")}
              />
            )}
          {!isSelected &&
            type === "community" &&
            badgeNumber !== undefined &&
            badgeNumber > 0 && (
              <NotificationsBubble numberOfEvents={badgeNumber} />
            )}
        </ListItemSecondaryAction>
      </ListItem>
      {sublist && (
        <List component="div" disablePadding>
          {sublist?.map((listItem, index) => {
            return (
              <React.Fragment key={`${listItem.type}-${listItem.id}`}>
                <DropdownListItem
                  listItem={listItem}
                  selected={selected}
                  nested={true}
                  handleClick={handleClick}
                />
                {sublist.length > 1 && index < sublist.length - 1 && (
                  <div
                    key={`divider-${listItem.id}`}
                    className={classes.nested}
                  >
                    <Divider
                      variant="middle"
                      classes={{ middle: classes.middleNested }}
                    />
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </List>
      )}
    </React.Fragment>
  );
};

interface IProps {
  listItem: IDropdownListItem;
  selected?: string;
  nested?: boolean;
  handleClick: (id: string, itemType: string) => void;
}
