export const ALEXA = "alexa";

// Possible request errors
export const ErrorCodes: { [key: string]: string } = {
  RESET_TOKEN_INVALID: "reset_token_invalid",
  INVALID_EMAIL: "invalid_email",
  SERVER_INTERNAL_ERROR: "server_internal_error",
  INVALID_EMAIL_PASSWORD: "invalid_email_password",
  PIN_EXISTS: "pin_exists",
  INVALID_PASSWORD_PIN: "invalid_password_pin",
  INVALID_VERIFICATION_TOKEN: "invalid_verification_token",
  VERIFICATION_TOKEN_EXPIRED: "verification_token_expired",
  UNVALIDATED_ACCOUNT: "unvalidated_account",
  EMAIL_IN_USE: "email_in_use",
  EMAIL_ALREADY_EXISTS: "email_already_exist",
  LAST_ADMIN: "last_admin",
  INTEGRITY_ERROR: "integrity_error",
  EMERGENCY_CONTACT_LAST_PHONE_NUMBER: "emergency_contact_last_phone_number",
  NAME_CONFLICT: "name_conflict",

  TOKEN_NOT_FOUND: "token_not_found",
  TOKEN_INVALID: "token_invalid",
  TOKEN_EXPIRED: "token_expired",
  BAD_REQUEST: "bad_request",
  INITIALIZING: "initializing",
  UNAUTHORIZED: "unauthorized",
  NOT_FOUND: "not_found",
  GATEWAY_NOT_CONNECTED: "gateway_not_connected",
  ZONE_NAME_CONFLICT: "duplicate_zone",
};

// Possible invalid request errors
export const InvalidRequestErrorCodes: { [key: string]: string } = {
  invalid_email_password: "Invalid email or password",
};

export const StorageKeys = {
  USER: "user",
  SELECTED_COMMUNITY_ID: "selected_community_id",
  SELECTED_ORGANIZATION_ID: "selected_organization_id",
  SELECTED_TIMEFRAME: "selected_timeframe",
  PARENT_CLIENT_ID: "parent_client_id",
  APP_LOG: "app_log",
  ENV: "environment",
  USER_ID: "user_id",
  VERSION: "version",
};

export const NOTIFICATIONS_LIMIT = 100;
export const ORG_NOTIFICATIONS_PER_COMMUNITY = 250;
export const LOG_LINES_LIMIT = 1000;
