import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  EventStatus,
  RoleTypes,
  SYSTEM_RESOLVED_PAUSED,
  Views,
} from "../../../helpers/constants";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import Grid from "@material-ui/core/Grid/Grid";
import { Box, Button, Popover, Theme } from "@material-ui/core";
import { DateTimeLongFormat } from "../../../helpers/datetime";
import { AssignResponderList } from "./AssignResponderList";
import { ResolveNotification } from "./ResolveNotification";
import { isPendingUser } from "../dashboardSlice";
import { IEvent } from "../../../services/dashboard.services";
import { useAppSelector } from "../../app/appHooks";
import { IUserDetails } from "../../../services/header.services";
import useCommunityUnitTimezone from "../../dashboard/hooks/useCommunityUnitTimezone";

const useStyles = makeStyles<Theme, IProps>((theme: Theme) =>
  createStyles({
    buttonRoot: {
      minWidth: "95px",
      [theme.breakpoints.up("md")]: {
        minWidth: "100px",
      },
      [theme.breakpoints.up("lg")]: {
        minWidth: "110px",
      },
      [theme.breakpoints.up("xl")]: {
        minWidth: "115px",
      },
    },
    buttonUnassigned: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.light.light,
      "&:hover": {
        backgroundColor: theme.palette.error.light,
      },
    },
    buttonAssigned: {
      color: theme.palette.error.main,
      border: `1px solid ${theme.palette.error.main}`,
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.up("lg")]: {
        justifyContent: "flex-end",
      },
    },
    detailsContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      [theme.breakpoints.down("md")]: {
        alignItems: "center",
        paddingBottom: theme.spacing(1),
      },
      [theme.breakpoints.between("md", "lg")]: {
        alignItems: "flex-start",
      },
      [theme.breakpoints.up("lg")]: {
        alignItems: "flex-start",
      },
    },
    fieldContainer: {
      paddingBottom: theme.spacing(0),
      overflowWrap: "break-word",
      whiteSpace: "pre-wrap",
      wordBreak: "break-all",
      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: theme.spacing(1),
      },
      [theme.breakpoints.up("md")]: {
        paddingBottom: theme.spacing(1),
        alignItems: "flex-start",
      },
    },
    popoverAssign: {
      border: `2px solid ${theme.palette.error.main}`,
      overflowY: "hidden",
      backgroundColor: theme.palette.error.main,
      padding: "0.1px",
    },
    popoverResolve: {
      border: `2px solid ${theme.palette.primary.main}`,
      overflowY: "hidden",
      backgroundColor: theme.palette.primary.main,
      padding: "0.1px",
    },
    detailsTable: {
      verticalAlign: "top",
      "& > tr": {
        height: "40px",
      },
    },
    notificationsLayout: {
      display: "table",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    responseLayout: {
      display: "flex",
      [theme.breakpoints.up("lg")]: {
        display: (props) =>
          props.layout === Views.NOTIFICATIONS ? "none" : "flex",
      },
    },
  })
);

export default function EventWorkflow(props: IProps) {
  const { event, layout } = props;
  /* ---- State ---- */
  const [anchorEl, setAnchorEl] = useState(null);
  const [trigeringEl, setTriggeringEl] = useState("");

  /* ---- Hooks ---- */
  const { t } = useTranslation();
  const classes = useStyles(props);

  /* ---- Selectors ---- */
  const currentUser = useAppSelector((state) => state.headerState.user);

  const checkIsUnitAccessible = (
    user: IUserDetails,
    unitID: string | undefined
  ) => {
    if (user.role.name === RoleTypes.pro_family) {
      return user.units.map((item) => item.id).includes(unitID ?? "");
    }
    if (
      user.role.name === RoleTypes.admin ||
      user.role.name === RoleTypes.caregiver
    ) {
      const hasAllUnits = user.role.all_units === null || user.role.all_units;
      const hasAccessToUnit = user.units
        .map((item) => item.id)
        .includes(unitID ?? "");
      return hasAllUnits || hasAccessToUnit;
    }
    return false;
  };

  const users = useAppSelector((state) => {
    if (state.headerState.selectedCommunity !== undefined) {
      return state.headerState.users
        ?.filter((user) => isPendingUser(user))
        .filter((user) => user.role.name !== RoleTypes.installer)
        .filter((user) => checkIsUnitAccessible(user, event?.unit_id));
    } else if (
      state.headerState.selectedOrganization !== undefined &&
      state.headerState.organizationData !== undefined &&
      event !== undefined &&
      event.community_id
    ) {
      return state.headerState.organizationData[event.community_id].users
        .filter((user) => isPendingUser(user))
        .filter((user) => user.role.name !== RoleTypes.installer);
    }
    return undefined;
  });
  const eventResolutions = useAppSelector((state) => {
    if (state.headerState.selectedCommunity !== undefined) {
      return state.headerState.eventResolutions;
    } else if (
      state.headerState.selectedOrganization !== undefined &&
      state.headerState.organizationData !== undefined &&
      event !== undefined &&
      event.community_id
    ) {
      return state.headerState.organizationData[event.community_id]
        .eventResolutions;
    }
    return undefined;
  });

  /* ---- Methods ---- */
  const handleOpenDropdown = (event: any) => {
    setTriggeringEl(event.currentTarget.id);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  let eventStatus: EventStatus = EventStatus.UNASSIGNED;
  if (event) {
    if (event.status === "unassigned") {
      eventStatus = EventStatus.UNASSIGNED;
    }
    if (event.status === "assigned") {
      eventStatus = EventStatus.ASSIGNED;
    }
    if (event.status === "resolved") {
      eventStatus = EventStatus.RESOLVED;
    }
  }

  const responderUser = users?.find((user) => user.id === event?.responder_id);
  const systemResolved = event?.event_resolution_id === SYSTEM_RESOLVED_PAUSED;
  const responderEmail = systemResolved
    ? t("not_applicable_paused")
    : responderUser !== undefined
    ? responderUser.email
    : "-";
  const communityUnitTimezone = useCommunityUnitTimezone(
    event?.community_id,
    event?.unit_id
  );

  let respondedAt = "-";
  if (
    event &&
    event.time_assigned !== undefined &&
    event.time_assigned !== null
  ) {
    respondedAt = DateTimeLongFormat(
      event.time_assigned,
      communityUnitTimezone
    );
  }

  let timeResolved = "-";
  if (
    event &&
    event.time_resolved !== undefined &&
    event.time_resolved !== null
  ) {
    timeResolved = DateTimeLongFormat(
      event.time_resolved,
      communityUnitTimezone
    );
  }

  let resolutionDesc = "-";
  if (event && event.event_resolution_id !== null && eventResolutions) {
    if (systemResolved) {
      resolutionDesc = t("stackcare_paused");
    } else {
      const resolution = eventResolutions.find(
        (resolution) => resolution.id === event.event_resolution_id
      );
      if (resolution && resolution.name)
        resolutionDesc = t(`${resolution.name}_description`);
    }
  }

  const open = Boolean(anchorEl);

  const isDashboard = layout === Views.DASHBOARD;
  const isNotifications = layout === Views.NOTIFICATIONS;

  return (
    <React.Fragment>
      {isNotifications && (
        <table className={classes.notificationsLayout}>
          <tbody className={classes.detailsTable}>
            <tr>
              <td style={{ width: "30%" }}>{t("responder").toUpperCase()}</td>
              <td style={{ width: "50%" }}>
                {eventStatus === EventStatus.UNASSIGNED ? "-" : responderEmail}
              </td>
              <td colSpan={2}>
                <Button
                  id="assign-button"
                  classes={{ root: classes.buttonRoot }}
                  className={
                    eventStatus === EventStatus.UNASSIGNED
                      ? classes.buttonUnassigned
                      : eventStatus === EventStatus.ASSIGNED
                      ? classes.buttonAssigned
                      : ""
                  }
                  onClick={handleOpenDropdown}
                  variant={
                    eventStatus === EventStatus.UNASSIGNED
                      ? "contained"
                      : "outlined"
                  }
                  disabled={eventStatus === EventStatus.RESOLVED}
                >
                  {eventStatus === EventStatus.UNASSIGNED
                    ? t("assign")
                    : t("reassign")}
                </Button>
              </td>
            </tr>
            <tr>
              <td>{t("responded").toUpperCase()}</td>
              <td>
                {" "}
                {eventStatus === EventStatus.UNASSIGNED ? "-" : respondedAt}
              </td>
            </tr>

            <tr>
              <td style={{ width: "30%" }}>{t("resolved").toUpperCase()}</td>
              <td style={{ width: "50%" }}>
                {event && event.status === EventStatus.RESOLVED
                  ? timeResolved
                  : "-"}
              </td>
              <td colSpan={2}>
                <Button
                  id="resolve-button"
                  classes={{ root: classes.buttonRoot }}
                  color={
                    eventStatus === EventStatus.UNASSIGNED
                      ? "default"
                      : "primary"
                  }
                  onClick={handleOpenDropdown}
                  variant={
                    eventStatus === EventStatus.UNASSIGNED ||
                    eventStatus === EventStatus.RESOLVED
                      ? "outlined"
                      : "contained"
                  }
                  disabled={eventStatus === EventStatus.UNASSIGNED}
                >
                  {t("resolve")}
                </Button>
              </td>
            </tr>
            <tr>
              <td>{t("resolution").toUpperCase()}</td>
              <td>
                {event && event.status === EventStatus.RESOLVED
                  ? resolutionDesc
                  : "-"}
              </td>
            </tr>
          </tbody>
        </table>
      )}
      <Grid
        className={classes.responseLayout}
        container
        spacing={2}
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          md={isDashboard ? 12 : 6}
          lg={7}
          xl={8}
          className={classes.detailsContainer}
        >
          <Box className={classes.fieldContainer}>
            <Box>{t("responder").toUpperCase()}</Box>
            <Box>
              {eventStatus === EventStatus.UNASSIGNED ? "-" : responderEmail}
            </Box>
          </Box>
          {isNotifications && (
            <Box className={classes.fieldContainer}>
              <Box>{t("responded").toUpperCase()}</Box>
              <Box>
                {eventStatus === EventStatus.UNASSIGNED ? "-" : respondedAt}
              </Box>
            </Box>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={isDashboard ? 12 : 6}
          lg={5}
          xl={4}
          className={classes.buttonContainer}
        >
          <Button
            id="assign-button"
            classes={{ root: classes.buttonRoot }}
            className={
              eventStatus === EventStatus.UNASSIGNED
                ? classes.buttonUnassigned
                : eventStatus === EventStatus.ASSIGNED
                ? classes.buttonAssigned
                : ""
            }
            onClick={handleOpenDropdown}
            variant={
              eventStatus === EventStatus.UNASSIGNED ? "contained" : "outlined"
            }
            disabled={eventStatus === EventStatus.RESOLVED}
          >
            {eventStatus === EventStatus.UNASSIGNED
              ? t("assign")
              : t("reassign")}
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          md={isDashboard ? 12 : 6}
          lg={7}
          xl={8}
          className={classes.detailsContainer}
        >
          <Box className={classes.fieldContainer}>
            <Box>{t("resolved").toUpperCase()}</Box>
            <Box>
              {event && event.status === EventStatus.RESOLVED
                ? timeResolved
                : "-"}
            </Box>
          </Box>
          {isNotifications && (
            <Box className={classes.fieldContainer}>
              <Box>{t("resolution").toUpperCase()}</Box>
              <Box>
                {event && event.status === EventStatus.RESOLVED
                  ? resolutionDesc
                  : "-"}
              </Box>
            </Box>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={isDashboard ? 12 : 6}
          lg={5}
          xl={4}
          className={classes.buttonContainer}
        >
          <Button
            id="resolve-button"
            classes={{ root: classes.buttonRoot }}
            color={
              eventStatus === EventStatus.UNASSIGNED ? "default" : "primary"
            }
            onClick={handleOpenDropdown}
            variant={
              eventStatus === EventStatus.UNASSIGNED ||
              eventStatus === EventStatus.RESOLVED
                ? "outlined"
                : "contained"
            }
            disabled={eventStatus === EventStatus.UNASSIGNED}
          >
            {t("resolve")}
          </Button>
        </Grid>
      </Grid>
      <Popover
        open={open}
        anchorEl={anchorEl}
        classes={{
          paper:
            trigeringEl === "assign-button"
              ? classes.popoverAssign
              : classes.popoverResolve,
        }}
        onClose={handleCloseDropdown}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        style={{ zIndex: 1200 }}
      >
        {(eventStatus === EventStatus.UNASSIGNED ||
          eventStatus === EventStatus.ASSIGNED) &&
          trigeringEl === "assign-button" && (
            <AssignResponderList
              eventID={event?.id}
              users={users}
              currentUser={currentUser}
              responder={event?.responder_id}
              eventStatus={eventStatus}
              fromDashboard={isDashboard}
              fromNotifications={isNotifications}
              onClose={handleCloseDropdown}
            />
          )}
        {(eventStatus === EventStatus.ASSIGNED ||
          eventStatus === EventStatus.RESOLVED) &&
          trigeringEl === "resolve-button" && (
            <ResolveNotification
              currentUser={currentUser}
              eventID={event?.id}
              eventResolutions={eventResolutions}
              resolutionID={event?.event_resolution_id}
              onClose={handleCloseDropdown}
              fromDashboard={isDashboard}
              fromNotifications={isNotifications}
            />
          )}
      </Popover>
    </React.Fragment>
  );
}

interface IProps {
  event?: IEvent;
  layout: string;
}
