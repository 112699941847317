import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ExclamationPoint from "../../assets/img/icon.dashboard.resident_exclamationpoint.svg";
import {
  DashboardViewTypes,
  ReducerStates,
  RoleTypes,
  sortAlphabetical,
  Views,
} from "../../helpers/constants";
import {
  loadCommunityData,
  loadOrganizationData,
  updateCommunityBadges,
} from "./headerThunks";
import {
  changeSelectedCommunity,
  changeSelectedOrganization,
} from "./headerSlice";
import { useAppSelector } from "../app/appHooks";
import { DropdownListItem } from "./DropdownListItem";
import {
  Badge,
  Box,
  Button,
  Divider,
  List,
  ListSubheader,
  Popover,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import useOnlineStatus from "react-online-hook";
import { ICommunities } from "../../services/header.services";
import useInterval from "../app/hooks/useInterval";

const communitySelectorWidth = "350px";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRoot: {
      textTransform: "none",
    },
    selectContainer: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "center",
    },
    communitySelector: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      maxWidth: communitySelectorWidth,
      marginLeft: theme.spacing(1),
      color: theme.palette.light.light,
    },
    communitiesContainerPopover: {
      minWidth: `${communitySelectorWidth} !important`,
      maxHeight: "70vh !important",
    },
    communityItem: {
      backgroundColor: "#fff",
    },
    bold: {
      fontWeight: "bolder",
    },
    communityHelpContainer: {
      margin: theme.spacing("auto", 1),
    },
    communityHelpIcon: {
      display: "flex",
      alignItems: "center",
    },
    badgeContent: {
      display: "flex",
    },
    eventsBubble: {
      height: "20px",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.light.light,
      minWidth: "20px",
      padding: theme.spacing(0.5),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "15px",
      margin: "auto",
    },
    badgeRoot: {
      width: "100%",
      maxWidth: communitySelectorWidth,
    },
    badgeContainer: {
      backgroundColor: theme.palette.dark.main,
      height: "30px",
    },
    badgePosition: {
      right: "20px",
    },
    buttonBorder: {
      border: `1px solid ${theme.palette.light.light}`,
    },
    listHeader: {
      backgroundColor: theme.palette.light.light,
    },
    communityTitle: {
      display: "block",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  })
);

const MINUTE_MS = 60000;

export interface IDropdownListItem {
  id: string;
  name: string;
  helpEvent?: boolean;
  unreadEvents?: number;
  systemIssues?: number;
  installerRole?: boolean;
  type: string;
  sublist?: IDropdownListItem[];
}

export const getDropdownList = (communities: ICommunities[]) => {
  const list: IDropdownListItem[] = [];
  communities.forEach((community) => {
    const hasOrgRole = community.role.find(
      (roleItem) => roleItem === RoleTypes.org
    );
    const isInstaller = !!community?.role.includes(RoleTypes.installer);

    // Community belongs to an org and the user has the user role
    if (hasOrgRole && community.organization_id !== null) {
      // Search other communities in the org
      const sameOrganizationCount = communities.reduce(
        (result: number, _community) => {
          if (community.organization_id === _community.organization_id)
            return result + 1;
          return result;
        },
        0
      );

      // If other communities are found, then search org and add to sublist or add a new org
      if (sameOrganizationCount > 1) {
        const orgIndex = list.findIndex(
          (listItem) => listItem.id === community.organization_id
        );
        // Add to existing org or create a new one
        if (orgIndex >= 0) {
          list[orgIndex].sublist?.push({
            id: community.id,
            name: community.name,
            helpEvent: community.help_event,
            unreadEvents: community.unread_events,
            systemIssues: community.system_issues,
            installerRole: isInstaller,
            type: DashboardViewTypes.COMMUNITY,
          });
        } else {
          list.push({
            id: community.organization_id ?? "",
            name: community.organization_name ?? "",
            type: DashboardViewTypes.ORGANIZATION,
            sublist: [
              {
                id: community.id,
                name: community.name,
                helpEvent: community.help_event,
                unreadEvents: community.unread_events,
                systemIssues: community.system_issues,
                installerRole: isInstaller,
                type: DashboardViewTypes.COMMUNITY,
              },
            ],
          });
        }
      } else {
        list.push({
          id: community.id,
          name: community.name,
          helpEvent: community.help_event,
          unreadEvents: community.unread_events,
          systemIssues: community.system_issues,
          installerRole: isInstaller,
          type: DashboardViewTypes.COMMUNITY,
        });
      }
    } else {
      list.push({
        id: community.id,
        name: community.name,
        helpEvent: community.help_event,
        unreadEvents: community.unread_events,
        systemIssues: community.system_issues,
        installerRole: isInstaller,
        type: DashboardViewTypes.COMMUNITY,
      });
    }
  });

  list.sort((a, b) => sortAlphabetical(a.name, b.name));
  list.forEach((listItem) => {
    if (
      listItem.type === DashboardViewTypes.ORGANIZATION &&
      listItem.sublist &&
      listItem.sublist.length > 1
    ) {
      listItem.sublist.sort((a, b) => sortAlphabetical(a.name, b.name));
    }
  });
  return list;
};

export default function CommunityDropdown() {
  /* ---- Hooks ---- */
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { isOnline } = useOnlineStatus();

  /* ---- State ---- */
  const [communities, setCommunities] = useState<ICommunities[] | undefined>(
    undefined
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedCommunity, setSelectedCommunity] = useState<
    ICommunities | undefined
  >(undefined);
  const [selectedOrganization, setSelectedOrganization] = useState<
    IDropdownListItem | undefined
  >(undefined);
  const [dropdownList, setDropdownlist] = useState<IDropdownListItem[]>([]);

  /* ---- Selectors ---- */
  const communitiesState = useAppSelector(
    (state) => state.headerState.communities
  );

  const selectedCommunityState = useAppSelector(
    (state) => state.headerState.selectedCommunity
  );

  const selectedOrganizationID = useAppSelector(
    (state) => state.headerState.selectedOrganization
  );

  const headerState = useAppSelector((state) => state.headerState.state);

  const users = useAppSelector((state) => state.headerState.users);

  const eventResolutions = useAppSelector(
    (state) => state.headerState.eventResolutions
  );
  const units = useAppSelector((state) => state.headerState.units);
  const organizationData = useAppSelector(
    (state) => state.headerState.organizationData
  );

  /* ---- Effects ---- */
  // Set communities in component state
  useEffect(() => {
    if (
      headerState === ReducerStates.SUCCEEDED &&
      communitiesState !== undefined
    ) {
      // Set selected community
      setCommunities(communitiesState);
    }
  }, [communitiesState, headerState]);

  // Get sorted list of communities and organizations
  useEffect(() => {
    if (headerState === ReducerStates.SUCCEEDED && communities !== undefined) {
      const dropdownItemList = getDropdownList(communities);
      setDropdownlist(dropdownItemList);
    }
  }, [communities, headerState]);

  // Request info from the backend each minute to check if there are new events or help events
  useInterval(() => {
    if (isOnline) {
      dispatch(updateCommunityBadges());
    }
  }, MINUTE_MS);

  /* COMMUNITIES EFFECTS */
  // If there is a selected community in Redux state, set it in component
  useEffect(() => {
    if (
      headerState === ReducerStates.SUCCEEDED &&
      selectedCommunityState !== undefined
    ) {
      setSelectedCommunity(selectedCommunityState);
    } else {
      setSelectedOrganization(undefined);
    }
  }, [selectedCommunityState, headerState]);

  // Load community data such as resolutions and community users
  useEffect(() => {
    if (
      headerState === ReducerStates.SUCCEEDED &&
      selectedCommunityState !== undefined &&
      (users === undefined ||
        eventResolutions === undefined ||
        units === undefined)
    ) {
      dispatch(loadCommunityData(selectedCommunityState.id));
    }
  }, [
    headerState,
    dispatch,
    selectedCommunityState,
    users,
    eventResolutions,
    units,
  ]);

  /* ORGANIZATION EFFECTS */
  // If there is a selected organization in Redux state, set it in component
  useEffect(() => {
    if (
      headerState === ReducerStates.SUCCEEDED &&
      selectedOrganizationID !== undefined
    ) {
      const organization = dropdownList.find(
        (listItem) =>
          listItem.id === selectedOrganizationID &&
          listItem.type === DashboardViewTypes.ORGANIZATION
      );
      if (organization !== undefined) {
        setSelectedOrganization(organization);
      }
    } else {
      setSelectedOrganization(undefined);
    }
  }, [selectedOrganizationID, headerState, dropdownList]);

  // Load organization data such as resolutions and community users
  useEffect(() => {
    if (
      headerState === ReducerStates.SUCCEEDED &&
      selectedOrganization !== undefined &&
      organizationData === undefined
    ) {
      if (selectedOrganization.sublist === undefined) return;
      const communitiesArr = selectedOrganization.sublist.map(
        (community) => community.id
      );
      dispatch(loadOrganizationData(communitiesArr));
    }
  }, [headerState, dispatch, selectedOrganization, organizationData]);

  /* ---- Methods ---- */
  const handleOpenDropdown = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };
  const handleCommunityChange = (ID: string, listItemType: string) => {
    if (
      location.pathname !== Views.DASHBOARD &&
      location.pathname !== Views.SETTINGS &&
      listItemType === DashboardViewTypes.ORGANIZATION
    )
      history.push(Views.DASHBOARD);

    if (DashboardViewTypes.COMMUNITY === listItemType) {
      if (ID !== selectedCommunity?.id) {
        const newSelectedCommunity = communities?.find(
          (community) => community.id === ID
        );
        if (newSelectedCommunity) {
          setSelectedOrganization(undefined);
          setSelectedCommunity(newSelectedCommunity);
          dispatch(changeSelectedCommunity(newSelectedCommunity));
          dispatch(loadCommunityData(newSelectedCommunity.id));
        }
      }
    } else if (DashboardViewTypes.ORGANIZATION === listItemType) {
      if (ID !== selectedOrganization?.id) {
        const organization = dropdownList.find(
          (listItem) =>
            listItem.id === ID &&
            listItem.type === DashboardViewTypes.ORGANIZATION
        );
        if (organization !== undefined) {
          setSelectedCommunity(undefined);
          setSelectedOrganization(organization);
          dispatch(changeSelectedOrganization(ID));
          if (organization.sublist === undefined) return;
          const communitiesArr = organization.sublist.map(
            (community) => community.id
          );
          dispatch(loadOrganizationData(communitiesArr));
        }
      }
    }
    handleCloseDropdown();
  };

  const open = Boolean(anchorEl);
  const communityDropdownID = open ? "community-dropdown" : undefined;

  // Determine if help event exists in the pro communities (do not include the selected one)
  const HelpEventExists = Boolean(
    communities?.some((community) =>
      community.id !== selectedCommunity?.id ? community.help_event : false
    )
  );

  // Determine the total number of events in the pro communities (do not include the selected one)
  let initialValue = 0;
  let badgeNumber =
    communities?.reduce((accumulator, currentValue) => {
      if (currentValue.id !== selectedCommunity?.id) {
        const isInstaller = !!currentValue?.role.includes(RoleTypes.installer);
        const temp = !isInstaller
          ? currentValue.unread_events
          : currentValue.system_issues
          ? currentValue.system_issues
          : 0;
        return accumulator + temp;
      } else {
        return accumulator;
      }
    }, initialValue) || initialValue;

  // Define the name to display
  const selectedItemDisplayName =
    selectedOrganization !== undefined
      ? selectedOrganization.name
      : selectedCommunity !== undefined
      ? selectedCommunity.name
      : "";

  const selectedItem =
    selectedOrganization !== undefined
      ? selectedOrganization.id
      : selectedCommunity !== undefined
      ? selectedCommunity.id
      : undefined;

  return (
    <React.Fragment>
      {communities && communities.length > 0 ? (
        communities.length > 1 ? (
          <div className={classes.selectContainer}>
            <Badge
              classes={{
                root: classes.badgeRoot,
                badge: classes.badgeContainer,
                anchorOriginTopRightRectangular:
                  HelpEventExists && badgeNumber > 0
                    ? classes.badgePosition
                    : "",
              }}
              invisible={!HelpEventExists && badgeNumber === 0}
              badgeContent={
                <div className={classes.badgeContent}>
                  <HelpEventIcon
                    helpEvent={HelpEventExists}
                    altTextHelp={t("alt_text_community_help_badge")}
                  />
                  <NotificationsBubble numberOfEvents={badgeNumber} />
                </div>
              }
            >
              <Button
                aria-describedby={communityDropdownID}
                classes={{
                  root: classes.buttonRoot,
                  outlinedPrimary: classes.buttonBorder,
                }}
                variant="outlined"
                color="primary"
                className={classes.communitySelector}
                endIcon={
                  <ArrowDropDownIcon color="primary" style={{ fontSize: 30 }} />
                }
                onClick={handleOpenDropdown}
              >
                {selectedItemDisplayName}
              </Button>
            </Badge>
            <Popover
              id={communityDropdownID}
              open={open}
              classes={{
                paper: classes.communitiesContainerPopover,
              }}
              anchorEl={anchorEl}
              onClose={handleCloseDropdown}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <List
                aria-label="switch locations dropdown"
                subheader={
                  <ListSubheader
                    className={classes.listHeader}
                    id="nested-list-subheader"
                  >
                    <Box fontWeight="fontWeightBold" fontSize="large">
                      {t("switch_locations")}
                    </Box>
                  </ListSubheader>
                }
              >
                {dropdownList?.map((listItem, index) => {
                  return (
                    <React.Fragment key={`${listItem.type}-${listItem.id}`}>
                      <DropdownListItem
                        listItem={listItem}
                        selected={selectedItem}
                        handleClick={handleCommunityChange}
                      />
                      {dropdownList.length > 1 &&
                        index < dropdownList.length - 1 && (
                          <Divider variant="middle" />
                        )}
                    </React.Fragment>
                  );
                })}
              </List>
            </Popover>
          </div>
        ) : (
          <Box component="h1">{communities[0].name}</Box>
        )
      ) : (
        <React.Fragment>
          <Box padding="0px 8px" component="h2">
            {t("none")}
          </Box>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export const HelpEventIcon = (props: {
  helpEvent: boolean;
  altTextHelp: string;
}) => {
  const { helpEvent, altTextHelp } = props;
  /* Hooks */
  const classes = useStyles();
  return helpEvent ? (
    <Box className={classes.communityHelpContainer}>
      <img
        src={ExclamationPoint}
        alt={altTextHelp}
        draggable={false}
        className={classes.communityHelpIcon}
      />
    </Box>
  ) : null;
};

export const NotificationsBubble = (props: { numberOfEvents: number }) => {
  const classes = useStyles();
  const events: string =
    props.numberOfEvents < 100 ? String(props.numberOfEvents) : "99+";
  return props.numberOfEvents ? (
    <Box fontWeight="500" fontSize="smaller" className={classes.eventsBubble}>
      {events}
    </Box>
  ) : null;
};
