import { useState } from "react";
import {
  AccordionActions,
  AccordionDetails,
  Button,
  Divider,
  Grid,
  Input,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordion from "@material-ui/core/Accordion";
import { emailValidation } from "../../helpers/utils";
import { useAppDispatch, useAppSelector } from "../app/appHooks";
import {
  addCommunityUser,
  deleteCommunityUser,
  updateCommunityUser,
} from "../app/asyncThunkActions";
import { capitalize, RoleTypes } from "../../helpers/constants";
import { loadCommunityUsers } from "../header/headerThunks";
import {
  getResidentName,
  isHelpAtHome,
  isPendingUser as isNotPendingUser,
} from "../dashboard/dashboardSlice";
import { ExpandMoreOutlined } from "@material-ui/icons";
import clsx from "clsx";
import { getUserName } from "./UsersSettingsList";
import { IPhoneNumber, IUserDetails } from "../../services/header.services";
import { PhoneNumberField } from "../common/PhoneNumberField";
import { AddItemAccordion } from "./common/AddItemAccordion";
import { ConfirmDialog, DialogTypes } from "../common/ConfirmDialog";
import { IUpdateRoleData } from "../../services/settings.services";
import { showSoftNotification } from "../app/appSlice";
import { roleLabels } from "./common/RolePicker";
import { UpdateResidentContacts } from "./UpdateResidentContacts";
import { getRoleLabel } from "./settingsHelpers";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    residentContactsForm: {
      padding: theme.spacing(6, 7),
      height: "100%",
      overflowY: "auto",
      [theme.breakpoints.up("lg")]: {
        width: "850px",
      },
      [theme.breakpoints.down("lg")]: {
        width: "750px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "650px",
      },
    },
    formContainer: {
      overflowY: "auto",
      padding: theme.spacing(0, 2, 2, 4),
      paddingBottom: theme.spacing(4),
    },
    sectionTitle: {
      position: "relative",
      marginTop: 0,
      marginBottom: theme.spacing(4),
      textAlign: "center",
    },
    addListTitle: {
      marginTop: 0,
      marginBottom: theme.spacing(2),
      fontWeight: theme.typography.body1.fontWeight,
    },
    inputWidth: {
      width: "100%",
    },
    formRow: {
      display: "flex",
      alignItems: "center",
    },
    inputContainer: {
      display: "flex",
      width: "250px",
    },
    formLabel: {
      paddingRight: theme.spacing(2),
    },
    buttons: {
      fontFamily: theme.typography.primaryFontFamily,
    },
    bold: {
      fontWeight: "bold",
    },
    accordionListItem: {
      marginBottom: theme.spacing(1),
    },
    pendingSectionContainer: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
    },
    pendingSectionOne: {
      display: "inline-flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    pendingSectionTwo: {
      paddingRight: theme.spacing(5),
    },
    sectionOne: {
      display: "inline-flex",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    deleteButton: {
      color: theme.palette.error.main,
    },
    appUnitContainer: {
      marginBottom: theme.spacing(1),
    },
    itemRowContainer: {
      display: "flex",
      alignItems: "baseline",
      margin: theme.spacing(1, 0),
    },
    itemRowLabel: {
      paddingRight: theme.spacing(2),
    },
    itemRowContent: {
      overflowWrap: "anywhere",
      fontSize: "1rem",
    },
  })
);

const ItemRow = (props: { label: string; children: React.ReactNode }) => {
  const { label, children } = props;
  const classes = useStyles();
  return (
    <div className={classes.itemRowContainer}>
      <div className={classes.itemRowLabel}>{label.toUpperCase()}</div>

      <div className={classes.itemRowContent}>{children}</div>
    </div>
  );
};

const Accordion = withStyles((theme: Theme) => ({
  root: {
    border: "1px solid #ccc",
    borderRadius: "5px",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: 0,
      marginBottom: theme.spacing(1),
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    minHeight: 0,
    "&$expanded": {
      minHeight: 0,
    },
  },
  content: {
    "&$expanded": {
      margin: "0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const sortAppUsers = (a: IUserDetails, b: IUserDetails) => {
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  });

  const userIsPendingA = !isNotPendingUser(a);
  const userIsPendingB = !isNotPendingUser(b);

  // Send pending users to bottom of the list
  if (isNotPendingUser(a) && !isNotPendingUser(b)) return -1;
  if (!isNotPendingUser(b) && !isNotPendingUser(b)) return 1;

  if (!userIsPendingA && userIsPendingB) return -1;
  if (userIsPendingA && !userIsPendingB) return 1;

  const contactLastNameA = a.last_name ?? "";
  const contactLastNameB = b.last_name ?? "";

  const contactFirstNameA = a.first_name ?? "";
  const contactFirstNameB = b.first_name ?? "";

  // Compare last name and then first name
  if (collator.compare(contactLastNameA, contactLastNameB) !== 0) {
    return collator.compare(contactLastNameA, contactLastNameB);
  }
  return collator.compare(contactFirstNameA, contactFirstNameB);
};

export const ResidentContactsSettings = () => {
  /* Hooks */
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  /* State */
  const [userDetail, setUserDetail] = useState("");
  const [addNewUser, setAddNewUser] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [deleteRole, setDeleteRole] = useState(false);
  const [confirmFamilyUser, setConfirmFamilyUser] = useState("");

  /* Selectors */
  const communityID = useAppSelector(
    (state) => state.headerState.selectedCommunity?.id
  );
  const selectedUnit = useAppSelector(
    (state) => state.settingsState.selectedUnit
  );
  const users = useAppSelector((state) => state.headerState.users);

  const appUnitUsers = useAppSelector((state) =>
    state.headerState.users
      //Filter pro family roles
      ?.filter((user) => user.role.name === RoleTypes.pro_family)
      //Filter units for the selected unit
      .filter(
        (user) =>
          user.units.find(
            (unit) => unit.id === state.settingsState.selectedUnit
          ) !== undefined
      )
      .sort((a, b) => sortAppUsers(a, b))
  );

  const residentID = useAppSelector((state) => {
    const selectedUnit = state.settingsState.selectedUnit;
    if (selectedUnit !== undefined) {
      const unit = state.headerState.units?.find(
        (unit) => unit.id === selectedUnit
      );
      if (unit && unit.residents && unit.residents.length > 0) {
        return unit.residents[0].id;
      }
    }
    return undefined;
  });

  /* Effects */
  const validateEmail = () => {
    if (newUserEmail !== "") {
      if (emailValidation(newUserEmail)) {
        setEmailError(t("invalid_email"));
      } else {
        setEmailError("");
      }
    }
  };
  const handleSubmit = async () => {
    if (newUserEmail === "") return;
    if (communityID === undefined) return;
    if (selectedUnit === undefined) return;

    if (appUnitUsers && appUnitUsers.length > 0) {
      const alreadyExists = appUnitUsers.find(
        (user) => user.email === newUserEmail
      );
      if (alreadyExists) {
        dispatch(
          showSoftNotification(
            t("email_already_authorized", { email: alreadyExists.email })
          )
        );
        setEmailError("email_already_authorized");
        return;
      }
    }

    const existingUser = users?.find((user) => user.email === newUserEmail);

    // If an user exists with the inputted email
    if (existingUser !== undefined) {
      // Verify is a family role, if not, show error
      if (existingUser.role.name === RoleTypes.pro_family) {
        // Does not have other units
        if (existingUser.units.length === 0) {
          let roleData: IUpdateRoleData | undefined = undefined;
          const existingUnits = existingUser.units
            .filter((unit) => unit.id !== selectedUnit)
            .map((unit) => unit.id);

          roleData = {
            email: existingUser.email,
            communityID: communityID,
            roleData: {
              unit_ids: [...existingUnits, selectedUnit],
              all_units: false,
              name: existingUser.role.name,
            },
          };
          dispatch(updateCommunityUser({ roleData }));
          setAddNewUser(false);
          setNewUserEmail("");
          setEmailError("");
        } else {
          // Show confirm dialog
          if (existingUser.units.length > 0) {
            const unit = existingUser.units[0];
            const isHelpAtHomeUnit = isHelpAtHome(unit);

            let name = "-";
            if (isHelpAtHomeUnit) {
              name = unit
                ? getResidentName(unit.residents, {
                    fullFirstName: false,
                  })
                : "-";
            } else {
              name = unit ? unit.name : "-";
            }
            setConfirmFamilyUser(
              t("confirm_add_unit_pro_family", {
                email: existingUser.email,
                name: name,
              })
            );
          }
        }
      } else {
        dispatch(
          showSoftNotification(
            t("email_already_exists_pro_family", {
              email: existingUser.email,
              role: t(roleLabels[existingUser.role.name]),
            })
          )
        );
        setEmailError("role is not pro:family");
      }
    }
    // Is a new user
    else {
      const addCommunityRoleResult = await dispatch(
        addCommunityUser({
          name: RoleTypes.pro_family,
          email: newUserEmail,
          community_id: communityID,
          unit_ids: [selectedUnit],
          all_units: false,
          group_ids: [],
        })
      );

      if (addCommunityUser.fulfilled.match(addCommunityRoleResult)) {
        dispatch(loadCommunityUsers(communityID));
        setAddNewUser(false);
        setNewUserEmail("");
        setEmailError("");
      }
    }
  };

  const handleSubmitMultipleUnits = async () => {
    setConfirmFamilyUser("");
    if (newUserEmail === "") return;
    if (communityID === undefined) return;
    if (selectedUnit === undefined) return;

    const existingUser = users?.find((user) => user.email === newUserEmail);

    if (existingUser && existingUser.role.name === RoleTypes.pro_family) {
      let roleData: IUpdateRoleData | undefined = undefined;
      const existingUnits = existingUser.units
        .filter((unit) => unit.id !== selectedUnit)
        .map((unit) => unit.id);
      roleData = {
        email: existingUser.email,
        communityID: communityID,
        roleData: {
          unit_ids: [...existingUnits, selectedUnit],
          all_units: false,
          name: existingUser.role.name,
        },
      };

      dispatch(updateCommunityUser({ roleData }));
      setAddNewUser(false);
      setNewUserEmail("");
      setEmailError("");
    }
  };

  const handleToggleAppUserDetail = (userID: string) => {
    if (userDetail === userID) {
      setUserDetail("");
    } else {
      setUserDetail(userID);
    }
  };

  const enableSave = () => {
    return newUserEmail !== "" && emailError === "";
  };

  const handleCancelItemAdd = () => {
    setAddNewUser(false);
    setNewUserEmail("");
    setEmailError("");
  };

  const handleDeleteRole = () => {
    if (communityID === undefined) return;
    if (appUnitUsers === undefined) return;

    const selectedUser = appUnitUsers.find(
      (_appUser) => _appUser.id === userDetail
    );
    if (selectedUser && selectedUser.units.length > 0) {
      // Just one unit in the users, delete role
      if (selectedUser.units.length === 1) {
        dispatch(
          deleteCommunityUser({
            userEmail: selectedUser.email,
            community_id: communityID,
          })
        );
      }
      // More than 1, just delete from unit authorized units
      if (selectedUser.units.length > 1) {
        let roleData: IUpdateRoleData | undefined = undefined;
        const newUserUnits = selectedUser.units
          .filter((unit) => unit.id !== selectedUnit)
          .map((unit) => unit.id);
        roleData = {
          email: selectedUser.email,
          communityID: communityID,
          roleData: {
            unit_ids: newUserUnits,
            all_units: false,
            name: selectedUser.role.name,
          },
        };

        dispatch(updateCommunityUser({ roleData }));
      }
    }
  };

  const handleDeletePrompt = () => {
    setDeleteRole(true);
  };

  return (
    <Paper className={classes.residentContactsForm} elevation={3} square>
      <h2 className={classes.sectionTitle}>{t("resident_contacts")}</h2>
      <div className={classes.formContainer}>
        <h3 className={classes.addListTitle}>{t("app_users").toUpperCase()}</h3>
        <p>{t("contacts_app_users_summary")}</p>

        <ConfirmDialog
          dialogType={DialogTypes.warning}
          toggle={deleteRole}
          title={t("deauthorize")}
          message={t("deauthorize_user_confirm_message")}
          possitiveText={t("yes")}
          negativeText={t("no")}
          positiveAction={handleDeleteRole}
          negativeAction={() => void 0}
          onClose={() => setDeleteRole(false)}
        />
        {appUnitUsers && appUnitUsers.length > 0 && (
          <div id="app_users_items" className={classes.appUnitContainer}>
            {appUnitUsers.map((appUser) => {
              const isPending = !isNotPendingUser(appUser);
              const roleName = getRoleLabel(appUser.role?.name);
              const userName = getUserName(appUser);
              const phoneNumbers = appUser.phone_numbers;
              let phoneNumber: IPhoneNumber | undefined = undefined;
              if (phoneNumbers && phoneNumbers.length > 0) {
                phoneNumber = phoneNumbers[0];
              }

              const hasPhoneNumbers = phoneNumbers && phoneNumbers.length > 0;
              const openPanel = userDetail === appUser.id;
              return (
                <Accordion
                  className={classes.accordionListItem}
                  TransitionProps={{ unmountOnExit: true }}
                  key={appUser.id}
                  expanded={userDetail === appUser.id}
                >
                  {!isPending && (
                    <div>
                      <AccordionSummary
                        expandIcon={<ExpandMoreOutlined />}
                        onClick={() => handleToggleAppUserDetail(appUser.id)}
                      >
                        <Grid container>
                          <Grid item xs={6}>
                            <div
                              className={clsx(classes.sectionOne, classes.bold)}
                            >
                              {capitalize(userName)}
                              <Typography variant="caption">
                                {t("user")}
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={1}>
                            {t(roleName ?? "")}
                          </Grid>
                          <Grid item xs={5}>
                            {phoneNumber !== undefined && (
                              <PhoneNumberField
                                phoneNumber={phoneNumber}
                                notClickable
                                noPadding
                                rightAlign
                                noTruncate
                              />
                            )}
                          </Grid>
                        </Grid>
                      </AccordionSummary>
                      {openPanel && <Divider />}
                    </div>
                  )}
                  {isPending && (
                    <div>
                      {!openPanel && (
                        <AccordionSummary
                          expandIcon={<ExpandMoreOutlined />}
                          onClick={() => handleToggleAppUserDetail(appUser.id)}
                        >
                          <div className={classes.pendingSectionContainer}>
                            <div
                              className={clsx(
                                classes.pendingSectionOne,
                                classes.bold
                              )}
                            >
                              {appUser.email}
                              <Typography variant="caption">
                                {t("user")}
                              </Typography>
                            </div>
                            <div className={classes.pendingSectionTwo}>
                              {t("pending")}
                            </div>
                          </div>
                        </AccordionSummary>
                      )}
                    </div>
                  )}
                  <AccordionDetails>
                    {isPending ? (
                      <ItemRow label={t("email")}>
                        {appUser.email ?? ""}
                      </ItemRow>
                    ) : (
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={hasPhoneNumbers ? 7 : 12}
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <ItemRow label={t("first_name")}>
                            {appUser.first_name ?? "-"}
                          </ItemRow>
                          <ItemRow label={t("last_name")}>
                            {appUser.last_name ?? "-"}
                          </ItemRow>
                          <ItemRow label={t("email")}>
                            {appUser.email ?? ""}
                          </ItemRow>
                        </Grid>
                        {hasPhoneNumbers && (
                          <Grid item xs={5}>
                            <>
                              {phoneNumbers &&
                                phoneNumbers.length > 0 &&
                                phoneNumbers.map((phoneNumber, index) => {
                                  return (
                                    <div
                                      key={index}
                                      style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                        width: "100%",
                                      }}
                                    >
                                      <ItemRow
                                        label={
                                          phoneNumber.phone_type
                                            ? `${index + 1}. ${t(
                                                phoneNumber.phone_type
                                              )}`
                                            : ""
                                        }
                                      >
                                        <PhoneNumberField
                                          phoneNumber={phoneNumber}
                                          notClickable
                                          noPadding
                                          noIcon
                                          noTruncate
                                        />
                                      </ItemRow>
                                    </div>
                                  );
                                })}
                            </>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </AccordionDetails>
                  <Divider />
                  <AccordionActions>
                    <Button
                      className={classes.buttons}
                      onClick={() => setUserDetail("")}
                      size="small"
                      classes={{ label: classes.buttons }}
                    >
                      {t("cancel")}
                    </Button>
                    <Button
                      onClick={handleDeletePrompt}
                      size="small"
                      className={classes.deleteButton}
                      classes={{ label: classes.buttons }}
                    >
                      {t("deauthorize")}
                    </Button>
                  </AccordionActions>
                </Accordion>
              );
            })}
          </div>
        )}

        <AddItemAccordion
          expanded={addNewUser}
          toggle={setAddNewUser}
          cancelAction={handleCancelItemAdd}
          addText="invite"
          addAction={() => handleSubmit()}
          disabledAddAction={!enableSave()}
        >
          <div className={classes.formRow}>
            <div className={classes.formLabel}>
              {`${t("email").toUpperCase()}*`}
            </div>
            <div className={classes.inputContainer}>
              <Input
                id={"email"}
                type={"email"}
                className={classes.inputWidth}
                value={newUserEmail}
                onBlur={validateEmail}
                error={emailError !== ""}
                onChange={(e) => {
                  setNewUserEmail(e.currentTarget.value);
                  setEmailError("");
                }}
              />
            </div>
          </div>
          <ConfirmDialog
            dialogType={DialogTypes.primary}
            toggle={confirmFamilyUser !== ""}
            title={t("authorize_user")}
            message={confirmFamilyUser}
            possitiveText={t("yes")}
            negativeText={t("no")}
            positiveAction={handleSubmitMultipleUnits}
            negativeAction={() => void 0}
            onClose={() => setConfirmFamilyUser("")}
          />
        </AddItemAccordion>
        <div id="add_new"></div>
      </div>
      {residentID !== undefined && (
        <UpdateResidentContacts residentID={residentID} />
      )}
    </Paper>
  );
};
