import { IUnit } from "../../../services/dashboard.services";
import {
  getResidentCity,
  getResidentName,
  isHelpAtHome,
  isPendingUser,
} from "../../dashboard/dashboardSlice";
import states from "states-us";
import countries from "get-countries-info";
import getCountryISO3 from "country-iso-2-to-3";
import { IUserDetails } from "../../../services/header.services";

export const getUnitDisplayName = (unit?: IUnit) => {
  if (unit === undefined) return { primary: "-", secondary: "" };

  const isHelpAtHomeUnit = isHelpAtHome(unit);

  const unitName = unit.name;
  const resident = getResidentName(unit.residents, {
    fullFirstName: false,
    fullLastName: true,
  });

  const city = getResidentCity(unit.residents);

  if (isHelpAtHomeUnit) {
    return { primary: resident || "-", secondary: city };
  } else {
    return { primary: unitName || "-", secondary: resident };
  }
};

export const getCombinedDisplayName = (unit?: IUnit) => {
  if (unit === undefined) return "";

  const isHelpAtHomeUnit = isHelpAtHome(unit);

  const unitName = unit.name;
  const resident = getResidentName(unit.residents, {
    fullFirstName: false,
    fullLastName: false,
  });

  const city = getResidentCity(unit.residents);

  if (isHelpAtHomeUnit) {
    return city ? `${resident} - ${city}` : resident || "-";
  } else {
    return resident ? `${unitName} - ${resident}` : unitName || "-";
  }
};

export const getStatesList = (regionCode?: string | null) => {
  let stateList: { id: string; label: string }[] = [];
  if (regionCode === null || regionCode === undefined) {
    stateList = states
      .filter((state) => !state.territory)
      .map((state) => ({
        id: state.abbreviation,
        label: state.name,
      }));
  }
  if (regionCode === "US") {
    stateList = states
      .filter((state) => !state.territory)
      .map((state) => ({
        id: state.abbreviation,
        label: state.name,
      }));
  }
  if (regionCode && regionCode !== "US") {
    const provinces = countries(
      { ISO: getCountryISO3(regionCode) },
      "provinces"
    );
    if (Array.isArray(provinces) && provinces.length > 0) {
      stateList =
        provinces[0].map((state: string) => ({
          id: state,
          label: state,
        })) ?? [];
    } else {
      stateList = [];
    }
  }
  return stateList;
};

export const numberRange = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export function arrayEquals<T>(a: T[], b: T[]) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

type SortUserType = Pick<IUserDetails, "first_name" | "last_name" | "email">;

const sortFirstName = <T extends SortUserType>(a: T, b: T) => {
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  });

  const lastNameA = a.last_name ?? "";
  const lastNameB = b.last_name ?? "";

  const firstNameA = a.first_name ?? "";
  const firstNameB = b.first_name ?? "";

  if (
    collator.compare(firstNameA.toLowerCase(), firstNameB.toLowerCase()) !== 0
  ) {
    return collator.compare(firstNameA.toLowerCase(), firstNameB.toLowerCase());
  }
  if (
    collator.compare(lastNameA.toLowerCase(), lastNameB.toLowerCase()) !== 0
  ) {
    return collator.compare(lastNameA.toLowerCase(), lastNameB.toLowerCase());
  }
  // names must be equal
  return 0;
};

const sortPending = <T extends SortUserType>(a: T, b: T) => {
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  });

  if (collator.compare(a.email, b.email) !== 0) {
    return collator.compare(a.email, b.email);
  }

  // names must be equal
  return 0;
};

export const sortUsers = (list: IUserDetails[]) => {
  const pending = list
    .filter((item) => !isPendingUser(item))
    .sort((a, b) => sortPending(a, b));
  const notPending = list
    .filter((item) => isPendingUser(item))
    .sort((a, b) => sortFirstName(a, b));

  return [...notPending, ...pending];
};
