import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import MenuIcon from "@material-ui/icons/Menu";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ListItemLink from "./ListItemLink";

import logo from "../../assets/img/logo.png";
import { useAppSelector } from "../app/appHooks";
import {
  RoleTypes,
  SupportedShopCountries,
  Views,
  stackCareBizURL,
} from "../../helpers/constants";
import { IAppState } from "../../helpers/store";
import { Navigationicons } from "../../helpers/iconImports";
import ExternalLink from "./ExternalLink";
import { getFormattedProject } from "../login/Login";

const drawerWidth = 330;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    backdrop: {
      zIndex: theme.zIndex.appBar + 1,
      color: "#fff",
    },
    hideBackdrop: {
      zIndex: -1,
    },
    logoContainer: {
      display: "flex",
      alignItems: "center",
    },
    logoImg: {
      maxWidth: "100%",
      display: "block",
      height: "auto",
      maxHeight: "40px",
      marginLeft: theme.spacing(1),
    },
    drawer: {
      zIndex: theme.zIndex.appBar + 2,
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
      color: "#fff",
    },
    drawerOpen: {
      width: drawerWidth,
      backgroundColor: theme.palette.dark.main,
      color: "#fff",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      justifyContent: "space-between",
    },
    drawerClose: {
      backgroundColor: theme.palette.dark.main,
      color: "#fff",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 3),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    listContainer: {
      padding: theme.spacing(0, 3),
      whiteSpace: "nowrap",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-o-text-overflow": "ellipsis",
    },
    version: {
      fontSize: 12,
      color: theme.palette.paused.light,
      padding: theme.spacing(2),
      float: "left",
    },
    environment: {
      fontSize: 12,
      color: theme.palette.paused.light,
      padding: theme.spacing(2),
      float: "right",
    },
    menuBuffer: {
      marginTop: "40px",
    },
  })
);

const NavIcon = (props: { iconSrc: string; iconAlt: string }) => {
  return (
    <img
      src={props.iconSrc}
      alt={props.iconAlt}
      draggable={false}
      height={20}
    />
  );
};

const getManifest = function (
  callback: (error: Error | null, data: { stackVersion: string }) => void
) {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", "./manifest.json", true);
  xhr.responseType = "json";
  xhr.onload = function () {
    const status = xhr.status;
    if (status === 200) {
      callback(null, xhr.response);
    } else {
      const err = new Error(`${status}`);
      callback(err, { stackVersion: "" });
    }
  };
  xhr.send();
};

const MAX_ISSUES = 9999;

export default function NavigationPanel(props: IProps) {
  const { open, handleNavigationClose } = props;
  const [stackVersion, setStackVersion] = useState("");
  const showEnvironment = process.env.REACT_APP_ENVIRONMENT !== "production";

  /* ---- Hooks ---- */
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();

  /* ---- Selectors ---- */
  const selectedOrganizationID = useAppSelector(
    (state) => state.headerState.selectedOrganization
  );

  const getProject = useAppSelector(
    (state: IAppState) => state.appState.environment
  );

  const getVersion = useAppSelector(
    (state: IAppState) => state.appState.version
  );

  const deviceIssues = useAppSelector(
    (state) => state.devicesState.devicesIssues?.length
  );

  const systemIssues = useAppSelector((state) =>
    state.devicesState.systemIssues?.valueOf()
  );

  const installerRole = useAppSelector(
    (state) => state.headerState.installerRole
  );

  const selectedCommunity = useAppSelector(
    (state) => state.headerState.selectedCommunity
  );

  const isCaregiver = !!selectedCommunity?.role.includes(RoleTypes.caregiver);

  const issueCount = installerRole ? systemIssues : deviceIssues;

  /* ---- Refs ---- */
  const wrapperRef = useRef<HTMLDivElement>(null);

  /* ---- Effects ---- */
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        wrapperRef.current &&
        wrapperRef.current !== null &&
        !wrapperRef.current.contains(event.target)
      ) {
        handleNavigationClose();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleNavigationClose, wrapperRef]);

  let communityCountry =
    selectedCommunity && selectedCommunity.country
      ? selectedCommunity.country
      : "";

  const menuOptions = [
    {
      text: "dashboard",
      icon: (
        <NavIcon
          iconSrc={Navigationicons.DashboardIcon}
          iconAlt={"dashboard icon"}
        />
      ),
      location: Views.DASHBOARD,
      disabled: false,
      visible: true,
      action: undefined,
    },
    {
      text: "notifications",
      icon: (
        <NavIcon
          iconSrc={Navigationicons.NotificationsIcon}
          iconAlt={"notifications icon"}
        />
      ),
      location: Views.NOTIFICATIONS,
      disabled: installerRole,
      visible: selectedOrganizationID === undefined && !installerRole,
      action: undefined,
    },
    {
      text: "analytics",
      icon: (
        <NavIcon
          iconSrc={Navigationicons.AnalyticsIcon}
          iconAlt={"analytics icon"}
        />
      ),
      location: Views.ANALYTICS,
      disabled: installerRole,
      visible: selectedOrganizationID === undefined && !installerRole,
      action: undefined,
    },
    {
      text: "reports",
      icon: (
        <NavIcon
          iconSrc={Navigationicons.ReportsIconWhite}
          iconAlt={"analytics icon"}
        />
      ),
      location: Views.REPORTS,
      disabled: installerRole,
      visible: selectedOrganizationID === undefined && !installerRole,
      action: undefined,
    },
    {
      text: "devices",
      icon: (
        <NavIcon
          iconSrc={Navigationicons.DevicesIcon}
          iconAlt={"device icon"}
        />
      ),
      location: Views.DEVICES,
      disabled: isCaregiver,
      visible: selectedOrganizationID === undefined && !isCaregiver,
      action: undefined,
    },
    {
      text: "settings",
      icon: (
        <NavIcon
          iconSrc={Navigationicons.SettingsIcon}
          iconAlt={"settings icon"}
        />
      ),
      location: Views.SETTINGS,
      disabled: false,
      visible: true,
      action: undefined,
    },
  ];

  const externalOptions = [
    {
      text: "additional_kits",
      icon: (
        <NavIcon iconSrc={Navigationicons.ShopIcon} iconAlt={"shop icon"} />
      ),
      disabled: false,
      visible: SupportedShopCountries.includes(communityCountry),
      action: undefined,
    },
  ];

  useEffect(() => {
    getManifest((err, data) => {
      if (err === null && data !== null) {
        setStackVersion(data.stackVersion);
      }
    });
  }, []);

  return (
    <Backdrop
      className={clsx(classes.backdrop, !open && classes.hideBackdrop)}
      open={open}
    >
      <div className={classes.root}>
        <Drawer
          ref={wrapperRef}
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div>
            <div className={classes.toolbar}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleNavigationClose}
                edge="start"
              >
                <MenuIcon fontSize="large" />
              </IconButton>
              <div className={classes.logoContainer}>
                <img
                  src={logo}
                  alt="StackCare Logo"
                  className={classes.logoImg}
                  draggable={false}
                />
              </div>
            </div>
            <List
              onClick={() => handleNavigationClose()}
              className={classes.listContainer}
            >
              {menuOptions.map((menuOption) => {
                let badge = undefined;
                if (
                  menuOption.text === "devices" &&
                  issueCount !== undefined &&
                  issueCount !== 0
                ) {
                  badge =
                    issueCount > MAX_ISSUES
                      ? `${MAX_ISSUES}+`
                      : `${issueCount}`;
                }

                return (
                  <ListItemLink
                    primary={t(menuOption.text)}
                    icon={menuOption.icon}
                    key={menuOption.text}
                    to={menuOption.location}
                    selected={menuOption.location === location.pathname}
                    disabled={menuOption.disabled}
                    action={menuOption.action}
                    visible={menuOption.visible}
                    badge={badge}
                  />
                );
              })}
              <div className={classes.menuBuffer} />
              {externalOptions.map((menuOption) => {
                return (
                  <ExternalLink
                    primary={t(menuOption.text)}
                    icon={menuOption.icon}
                    key={menuOption.text}
                    disabled={menuOption.disabled}
                    visible={menuOption.visible}
                    link={stackCareBizURL}
                  />
                );
              })}
            </List>
          </div>
          <div>
            <span className={classes.version}>{`${t(
              "version"
            )}: ${stackVersion}`}</span>
            {showEnvironment && (
              <span className={classes.environment}>
                {getFormattedProject(getVersion, getProject)}
              </span>
            )}
          </div>
        </Drawer>
      </div>
    </Backdrop>
  );
}

interface IProps {
  open: boolean;
  handleNavigationClose: () => void;
}
