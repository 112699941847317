import { Grid, List, withStyles } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import MuiListItem from "@material-ui/core/ListItem";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../app/appHooks";
import { setSelectedUnit } from "./devicesSlice";
import clsx from "clsx";
import { getUnitDisplayName } from "../settings/common/helpers";
import { useEffect, useRef } from "react";
import { minToolbarHeight } from "../app/App";

const useStyles = makeStyles<Theme, Boolean>((theme: Theme) =>
  createStyles({
    unitListContainer: {
      flex: "0 0 300px",
      backgroundColor: theme.palette.light.light,
      overflowY: "auto",
      height: (showBanner) =>
        `calc(100vh - ${minToolbarHeight(showBanner, theme)})`,
    },
    column: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    primaryText: {
      fontSize: theme.typography.subtitle2.fontSize,
    },
    secondaryText: {
      fontSize: "0.625rem",
    },
    alignRight: {
      textAlign: "right",
    },
    truncateEllipsis: {
      whiteSpace: "nowrap",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-o-text-overflow": "ellipsis",
    },
    green: {
      color: theme.palette.primary.main,
    },
    red: {
      color: theme.palette.error.main,
    },
    yellow: {
      color: theme.palette.warning.main,
    },
    gray: {
      color: theme.palette.gray.main,
    },
  })
);

const ListItem = withStyles(({ palette, spacing }) => ({
  root: {
    padding: spacing(1),
    borderRadius: "0px",
    "&$selected": {
      backgroundColor: `${palette.primary.main}4D !important `,
    },
    "&$disabled": {
      opacity: 1,
    },
    "&$selected:hover": {
      backgroundColor: palette.primary.main,
    },
    "&:hover": {
      backgroundColor: `${palette.primary.main}4D !important `,
    },
  },
  disabled: {},
  selected: {},
  divider: {},
}))(MuiListItem);

export function DevicesUnitList() {
  const showBanner = useAppSelector((state) => state.headerState.showBanner);

  const listEl = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLUListElement | null>(null);
  const classes = useStyles(showBanner);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  /* Selectors */
  const units = useAppSelector((state) => state.headerState.units);
  const devicesStatus = useAppSelector(
    (state) => state.devicesState.devicesIssues
  );
  const selectedUnit = useAppSelector(
    (state) => state.devicesState.selectedUnit
  );

  const handleSelectUnit = (unitID: string) => {
    dispatch(setSelectedUnit(unitID));
  };

  const hasResidents =
    units?.some((unit) => getUnitDisplayName(unit).secondary !== "") ?? false;

  useEffect(() => {
    if (listEl.current && ref2.current) {
      const rect = listEl.current.getBoundingClientRect();
      if (
        !(
          rect.top >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight)
        )
      ) {
        listEl.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }, [selectedUnit]);

  return (
    <List
      ref={ref2}
      aria-labelledby="unit-list"
      className={classes.unitListContainer}
    >
      {units?.map((unit) => {
        let unitDisplayName = getUnitDisplayName(unit);
        const deviceStatus = devicesStatus?.find(
          (item) => item.unit_id === unit.id
        );

        const unitInstalled =
          unit.gateway_id !== null && unit.gateway_id !== undefined;

        type StatusColor = "red" | "green" | "yellow" | "gray";

        type DeviceStatus = { color: StatusColor; text: string };

        const getDeviceStatus = (status: typeof deviceStatus): DeviceStatus => {
          if (status === undefined) return { color: "green", text: "online" };
          if (
            status.hub_status !== undefined &&
            status.hub_status === "offline"
          )
            return { color: "red", text: "gateway_offline" };
          if (
            status.offline_devices !== undefined &&
            status.offline_devices > 0
          )
            return { color: "red", text: "devices_offline" };
          if (
            status.low_battery_devices !== undefined &&
            status.low_battery_devices > 0
          )
            return { color: "yellow", text: "battery_low" };

          return { color: "green", text: "online" };
        };

        const unitDeviceStatus = unitInstalled
          ? getDeviceStatus(deviceStatus)
          : ({ color: "gray", text: "not_installed" } as DeviceStatus);

        return (
          <ListItem
            key={unit.id}
            button
            onClick={() => handleSelectUnit(unit.id)}
            ref={unit.id === selectedUnit ? listEl : undefined}
            selected={unit.id === selectedUnit}
            disabled={!unitInstalled}
            divider
            disableGutters
          >
            <Grid container justifyContent="space-between">
              <Grid item xs={7} className={classes.column}>
                <div
                  className={clsx(
                    classes.primaryText,
                    classes.truncateEllipsis
                  )}
                >
                  {unitDisplayName.primary}
                </div>
                <div
                  className={clsx(
                    classes.secondaryText,
                    classes.truncateEllipsis
                  )}
                >
                  {hasResidents
                    ? unitDisplayName.secondary || "‎"
                    : unitDisplayName.secondary}
                </div>
              </Grid>
              <Grid item xs={5}>
                <div
                  className={clsx(
                    classes.primaryText,
                    classes.alignRight,
                    classes[unitDeviceStatus.color]
                  )}
                >
                  {t(unitDeviceStatus.text)}
                </div>
              </Grid>
            </Grid>
          </ListItem>
        );
      })}
    </List>
  );
}
